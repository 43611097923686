import { PBKDF2 } from "crypto-js";
import pako from "pako";
import _ from "lodash";
// import {mapState} from "vuex";

export const position = {
    // computed: {
    //     ...mapState([
    //         'brokerMap',
    //     ])
    // },
    methods: {
        /**
		 * Trim Symbol
		 * @param {*} room
		 */
        trimSymbol (symbol, rules) {
            for (let i = 0; i < rules.length; i++) {
                if (symbol.indexOf(rules[i]) != -1) {
                    const ruleLength = rules[i].length;
                    const lastNChar = symbol.substring(symbol.length - ruleLength);

                    if (lastNChar == rules[i]) return symbol.replace(rules[i], "");
                }
            }
            return symbol;
        },
        unzip (data) {
            // Decode base64 (convert ascii to binary)
            const strData = atob(data);

            // Convert binary string to character-number array
            const charData = strData.split("").map(function (x) { return x.charCodeAt(0); });

            // Turn number array into byte-array
            const binData = new Uint8Array(charData);

            // Pako magic
            const newData = pako.inflate(binData);

            // Convert gunzipped byteArray back to ascii string:
            const result = String.fromCharCode.apply(null, new Uint16Array(newData));

            return result;
        },
        unzipVersion (message) {
            // 加密：btoa()，解密：atob()
            let strData = atob(message);
            // 字符串转数组 在循环返回一个 Unicode表所在位置的新数组
            const charData = strData.split("").map(x => x.charCodeAt(0));
            // Uint8Array 数组类型表示一个8位无符号整型数组，创建时内容被初始化为0。创建完后，可以以对象的方式或使用数组下标索引的方式引用数组中的元素
            const binData = new Uint8Array(charData);
            // 调用pako 解析
            const data = pako.inflate(binData);
            // 处理pako.js解压过长（大）数据时报错的问题
            // 数据量过大会报错RangeError: Maximum call stack size exceeded
            // 说明 String.fromCharCode.apply 不能接受过长的数组，那么我们可以想到将数组进行拆分->解压->拼接。
            const array = new Uint16Array(data);
            let dataRes = "";
            const chunk = 8 * 1024;
            let i;
            for (i = 0; i < array.length / chunk; i++) {
                dataRes += String.fromCharCode.apply(null, array.slice(i * chunk, (i + 1) * chunk));
            }
            // 批量拼接数据
            dataRes += String.fromCharCode.apply(null, array.slice(i * chunk));
            strData = dataRes;
            // 赋值decodeURIComponent(escape())解决中文乱码问题
            const result = decodeURIComponent(escape(strData));

            return result;
        },
        calculate (data, brokerId, logging = false, book = null) {
            // 使用socket.io拿的数据不需要unzip
            // let data = unzip(message);
            // data = this.unzip(data);

            // rocky
            if (logging) console.warn(JSON.parse(data));

            let newSummary = { L: 0, S: 0, N: 0, FPNL: 0, CPNL: 0, NPNL: 0 };
            let newObject = {};

            const symbolRules = JSON.parse(localStorage.getItem("brokerSymbolRule"))
                .filter(item => item.broker_id == brokerId)
                .map(item => item.rule)[0];

            const loginLength = Object.keys(JSON.parse(data).profitLoginMap).length;

            const pnlSymbol = JSON.parse(data).profitSymbolMap;
            const pnlSymbolToday = JSON.parse(data).profitTodaySymbolMap;
            const profitSymbolDetailMap = JSON.parse(data).profitSymbolDetailMap;
            const closedProfitTodaySymbolMap = JSON.parse(data).closedProfitTodaySymbolMap;
            const profitToday = JSON.parse(data).profitToday;

            let symbolList = [];

            for (const item in pnlSymbolToday) {
                const symbol = this.trimSymbol(item, symbolRules);
                // let symbol = item.split('-')[0];
                symbolList.push(symbol);
            }
            for (const item in profitSymbolDetailMap) {
                const symbol = this.trimSymbol(item, symbolRules);
                // let symbol = item.split('-')[0];
                symbolList.push(symbol);
            }
            for (const item in closedProfitTodaySymbolMap) {
                const symbol = this.trimSymbol(item, symbolRules);
                // let symbol = item.split('-')[0];
                symbolList.push(symbol);
            }
            for (const item in pnlSymbol) {
                const symbol = this.trimSymbol(item, symbolRules);
                // let symbol = item.split('-')[0];
                symbolList.push(symbol);
            }

            symbolList = [...new Set(symbolList)];

            newObject = {};

            // Object.assign({}, newObject);

            newSummary = { L: 0, S: 0, N: 0, FPNL: 0, CPNL: 0, NPNL: 0, FPNLMT4: 0 };

            Object.assign({ L: 0, S: 0, N: 0, FPNL: 0, CPNL: 0, NPNL: 0, FPNLMT4: 0 }, newSummary);

            for (const item in symbolList) {
                const symbol = symbolList[item];

                newObject[symbol] = {};

                newObject[symbol].eodPrice = {};
                newObject[symbol].closedPnl = 0;
                newObject[symbol].floatingPnl = 0;
                newObject[symbol].floatingPnlMt4 = 0;
                newObject[symbol].symbol = symbol;
                newObject[symbol].pbLong = 0;
                newObject[symbol].pbShort = 0;
                newObject[symbol].digits = 0;
                newObject[symbol].netPnl = 0;
                newObject[symbol].avgShortPrice = 0;
                newObject[symbol].avgLongPrice = 0;
                newObject[symbol].totalVolShort = 0;
                newObject[symbol].totalPriceShort = 0;
                newObject[symbol].totalVolLong = 0;
                newObject[symbol].totalPriceLong = 0;
                newObject[symbol].netPosition = 0;
                newObject[symbol].totalSumShort = 0;
                newObject[symbol].totalSumLong = 0;
            }

            for (const item in pnlSymbol) {
                let symbol = "";
                symbol = this.trimSymbol(item, symbolRules);
                // symbol = item.split('-')[0];
                newObject[symbol].floatingPnlMt4 += pnlSymbol[item];
            }

            for (const item in pnlSymbolToday) {
                let symbol = "";
                symbol = this.trimSymbol(item, symbolRules);
                // symbol = item.split('-')[0];
                newObject[symbol].floatingPnl += pnlSymbolToday[item];
                newObject[symbol].netPnl += pnlSymbolToday[item];
            }
            // console.log(newObject);

            for (const item in profitSymbolDetailMap) {
                const eodPriceAsk = profitSymbolDetailMap[item].eodPriceAsk;
                const eodPriceBid = profitSymbolDetailMap[item].eodPriceBid;

                const symbol = this.trimSymbol(item, symbolRules);

                newObject[symbol].eodPrice[item] = { eodAsk: eodPriceAsk, eodBid: eodPriceBid };

                const digits = parseFloat(profitSymbolDetailMap[item].digits);
                const posLong = parseFloat(profitSymbolDetailMap[item].longPositions);
                const posShort = parseFloat(profitSymbolDetailMap[item].shortPositions) * -1;

                newObject[symbol].digits = digits;
                newObject[symbol].pbLong += posLong;
                newObject[symbol].pbShort += posShort;
                newObject[symbol].totalSumShort += profitSymbolDetailMap[item].shortPositions * profitSymbolDetailMap[item].avgShortPrice;
                newObject[symbol].totalSumLong += profitSymbolDetailMap[item].longPositions * profitSymbolDetailMap[item].avgLongPrice;
                newObject[symbol].totalVolShort += profitSymbolDetailMap[item].shortPositions;
                newObject[symbol].totalVolLong += profitSymbolDetailMap[item].longPositions;

                const singleNet = posLong + posShort;
                newSummary.L += posLong;
                newSummary.S += posShort;
                newSummary.N += singleNet;
                newSummary.FPNL += newObject[symbol].floatingPnl;
                newSummary.FPNLMT4 += newObject[symbol].floatingPnlMt4;
                newSummary.CPNL += 0;
                newSummary.NPNL += newObject[symbol].floatingPnl;

                newObject[symbol].netPosition += singleNet;

                // if (parseFloat(profitSymbolDetailMap[item]['avgShortPrice']) != 0) newObject[symbol]['avgSellPrice'] = parseFloat(profitSymbolDetailMap[item]['avgShortPrice']).toFixed(digits);
                // if (parseFloat(profitSymbolDetailMap[item]['avgLongPrice']) != 0) newObject[symbol]['avgBuyPrice'] = parseFloat(profitSymbolDetailMap[item]['avgLongPrice']).toFixed(digits);
            }

            for (const item in closedProfitTodaySymbolMap) {
                // let symbol = "";
                const symbol = this.trimSymbol(item, symbolRules);
                // symbol = item.split('-')[0];
                const closedPnl = parseFloat(closedProfitTodaySymbolMap[item]);
                newObject[symbol].closedPnl += closedPnl; // 有问题
                newObject[symbol].netPnl += closedPnl;
                newSummary.CPNL += closedPnl;
                newSummary.NPNL += closedPnl;
            }

            newSummary.FPNL = profitToday;
            newSummary.NPNL = profitToday + newSummary.CPNL;

            if (book != null) newSummary.book = book;
            const finalResult = { newObject: newObject, newSummary: newSummary };

            return finalResult;
        },
        calculateLogin (data, selectedSymbol, brokerId) {
            if (selectedSymbol == "") return [];

            // let symbolRules = ["-", "c", "s", "."];
            // let symbolRules = JSON.parse(localStorage.getItem("brokerSymbolRule"));
            const symbolRules = JSON.parse(localStorage.getItem("brokerSymbolRule"))
                .filter(item => item.broker_id == brokerId)
                .map(item => item.rule)[0];

            // profitSymbolDetail
            // let d = JSON.parse(data);
            // let psd = d["profitSymbolDetail"];
            // console.log("@@@@@@@@@@@@@ ", psd);
            const psd = JSON.parse(data).profitSymbolDetailMap;
            const resultLong = [];
            const resultShort = [];
            // console.log(symbolRules);
            for (const item in psd) {
                const symbol = this.trimSymbol(item, symbolRules);
                // XAUUSD
                if (symbol != selectedSymbol) continue;

                for (const login in psd[item].loginsVolumeLong) {
                    const tempDataLong = {
                        login: login,
                        volume_short: 0,
                        volume_long: psd[item].loginsVolumeLong[login],
                        volume_net: psd[item].loginsVolumeLong[login]
                    };
                    resultLong.push(tempDataLong);
                }

                for (const login in psd[item].loginsVolumeShort) {
                    const tempDataShort = {
                        login: login,
                        volume_short: psd[item].loginsVolumeShort[login] * -1,
                        volume_long: 0,
                        volume_net: psd[item].loginsVolumeShort[login] * -1
                    };
                    resultShort.push(tempDataShort);
                }
            }

            // let rl = JSON.parse(JSON.stringify(resultLong))
            // let rs = JSON.parse(JSON.stringify(resultShort))

            // console.log("=================");
            // console.log("Long: ", resultLong);
            // console.log("Long: ", rl);
            // console.log("Short: ", rs);
            // console.log("=================");

            Array.prototype.push.apply(resultLong, resultShort);

            const holder = {};

            resultLong.forEach(d => {
                if (holder.hasOwnProperty(d.login.toString())) {
                    holder[d.login.toString()].volume_short = parseFloat(holder[d.login].volume_short) + parseFloat(d.volume_short);
                    holder[d.login.toString()].volume_long = parseFloat(holder[d.login].volume_long) + parseFloat(d.volume_long);
                    holder[d.login.toString()].volume_net = parseFloat(holder[d.login].volume_long) + parseFloat(d.volume_short);
                } else {
                    holder[d.login.toString()] = {
                        login: d.login,
                        volume_long: parseFloat(d.volume_long),
                        volume_short: parseFloat(d.volume_short),
                        volume_net: parseFloat(d.volume_net)
                    };
                }
            });

            const output = [];
            for (const prop in holder) {
                if (prop == 0) continue;
                if (prop == "NaN") continue;
                // console.log(prop);
                // console.log(holder[prop]);
                output.push({
                    login: holder[prop].login,
                    volume_long: parseFloat(holder[prop].volume_long),
                    volume_short: parseFloat(holder[prop].volume_short),
                    volume_net: parseFloat(holder[prop].volume_net)
                });
            }
            const res = output;
            // let res = output.sort((a, b) => parseFloat(b.volume_net) - parseFloat(a.volume_net));
            return res;
        }
    }
};
