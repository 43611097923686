<template>
    <div>
        <div id="exposureChart" style="height: 100%"></div>
    </div>
</template>
<script>
import * as apiChartExposure from "@components/api/pk/chart-exposure";
import * as echarts from "echarts";
import { mapState } from "vuex";

export default {
    props: {
        book: null,
        brokerId: null,
        topnumber: null
    },
    data () {
        return {
            noDataMessage: "No Data Available",
            chart: Object,
            data: [],
            apiResult: [],
            broker: "",
            domHeight: 400,
            option: {
                title: {
                    text: "",
                    left: "center",
                    top: "center",
                    textStyle: {
                        color: "#fff"
                    }
                },
                tooltip: {
                    trigger: "axis",
                    axisPointer: { // 坐标轴指示器，坐标轴触发有效
                        type: "shadow" // 默认为直线，可选为：'line' | 'shadow'
                    }
                },
                grid: {
                    left: 60,
                    right: 60,
                    top: 40,
                    bottom: 30
                },
                xAxis: {
                    type: "value",
                    position: "top",
                    axisLabel: { show: true, color: "#fff", formatter: (data) => { return data; } },
                    splitLine: { lineStyle: { type: "dashed" }, show: false },
                    axisLabel: {
                        color: "#fff",
                        formatter: function (value, index) {
                            let data = value;

                            if (Math.abs(value) > 1000000) {
                                data = (value / 1000000).toFixed(0) + "M";
                                return data;
                            } else if (Math.abs(value) >= 1000) {
                                data = (value / 1000).toFixed(0) + "K";
                                return data;
                            } else if (Math.abs(value) < 1000) {
                                data = value;
                                return data;
                            }
                            return value;
                        }
                    }
                },
                yAxis: {
                    type: "category",
                    axisLine: { show: false },
                    axisLabel: { show: true, color: "#fff", interval: 0 },
                    axisTick: { show: false },
                    splitLine: { show: false },
                    data: []
                },
                series: [
                    {
                        name: "Exposure",
                        type: "bar",
                        stack: "Total",
                        label: {
                            show: false,
                            formatter: function (a, b, c) {
                                const result = []; let counter = 0;
                                const numArray = a.data.value.toString().split(".");
                                const num = numArray[0];
                                let newStr = "";

                                if (Math.abs(a.data.value) > 1000000) {
                                    return (a.data.value / 1000000).toFixed(2).toString() + " M";
                                } else if (Math.abs(a.data.value) > 100000) {
                                    return (a.data.value / 1000).toFixed(2).toString() + " K";
                                } else if (Math.abs(a.data.value) > 10000) {
                                    return (a.data.value / 1000).toFixed(2).toString() + " K";
                                }

                                for (let i = num.length - 1; i >= 0; i--) {
                                    counter++;
                                    result.unshift(num[i]);
                                    if (!(counter % 3) && i != 0) result.unshift(",");
                                }

                                if (numArray.length > 1) {
                                    newStr = result.join("");
                                    newStr += "." + numArray[1];
                                    return newStr;
                                } else {
                                    return result.join("");
                                }
                            },
                            // formatter: function(value, index) {
                            //     let value;
                            //     if (Math.abs(value) >= 1000) {
                            //         value = (value/1000).toFixed(0) + 'k';
                            //     } else if (Math.abs(value) < 1000) {
                            //         value = value;
                            //     }
                            //     return value
                            // },
                            color: "#fff"
                        },
                        itemStyle: {
                            color: (data) => {
                                if (data.value > 0) return "#2c69ab";
                                if (data.value < 0) return "#c82c20";
                            }
                        },
                        data: []
                    }
                ]
            }
        };
    },
    computed: {
        ...mapState([
            "brokerMap"
        ])
    },
    methods: {
        sortitems (res) {
            let index = 0;
            let values = res.data.yaxis;
            for (const value of values) {
                value.xaxis = res.data.xaxis[index];
                index = index + 1;
            }
            values.sort((a, b) => {
                return Math.abs(a.value) - Math.abs(b.value);
            });

            let xaxis = [];
            let yaxis = [];
            let start = 0;
            let end = values.length;
            if (this.topnumber !== "All") {
                start = values.length - this.topnumber;
                start = start < 0 ? 0 : start
            }

            for (let i = start; i < end; i++) {
                xaxis.push(values[i].xaxis);
                yaxis.push({ value: values[i].value });
            }

            const self = this;
            this.apiResult = { xaxis: xaxis, yaxis: yaxis };
            self.option.yAxis.data = self.apiResult.xaxis;
            const newHeight = 90 + self.option.yAxis.data.length * 27;
            self.domHeight = newHeight > 400 ? 400 : newHeight;
            self.option.series[0].data = self.apiResult.yaxis;
            self.chart.setOption(self.option);
            self.chart.resize({ height: self.domHeight });
        },
        loadChart () {
            const self = this;
    		self.chart = echarts.init(document.getElementById("exposureChart"));

            const params = { book: self.book, broker_name: self.broker };
            apiChartExposure.create(params).then(res => {
                this.data = res;
                if (res.data.xaxis.length === 0) {
                    this.option.title.text = this.noDataMessage
                    self.chart.setOption(self.option);
                }
                self.apiResult = this.sortitems(res);
            });
        }
    },
    watch: {
        topnumber (nv, ov) {
            console.log(nv);
            this.sortitems(this.data);
        },
        // bookid (nv, ov) {
        //     this.loadChart();
        // }    
    },
    mounted () {
        const filterData = this.brokerMap.filter(item => item.broker_id == this.brokerId);
        this.broker = filterData[0].broker;
        // this.option.title.text = "CCY Exposure";
        this.loadChart();
    }
};
</script>
