<template>
    <div>
        <div :id="dom" style="width: 100%; height:400px; margin-top:10px"></div>
    </div>
</template>
<script>
import * as echarts from "echarts";
export default {
    props: {
        dom: null,
        // legendData: null,
        timeData: null,
        seriesData: null,
        loginValue: null,
        legendData: null
    },
    data () {
        return {
            chart: null,
            option: {
                // title: [
                //     {
                //         text: this.loginValue + " Snapshot",
                //         textStyle: { color: "#EEF1FA" },
                //         left: 50,
                //     },
                // ],

                // legend: {
                //     data: ["Daily PNL", "Equity", "Balance", "DPM"],
                //     textStyle: { color: "#B9B8CE" },
                //     top:20,
                // },
                grid: { left: 80, right: 160 },
                legend: {
                    data: this.legendData,
                    top: 30,
                    textStyle: { color: "#B9B8CE" }
                },
                tooltip: {
                    trigger: "axis"
                },
                // toolbox: {
                //     feature: {
                //         dataZoom: { yAxisIndex: "none" },
                //         restore: {},
                //         saveAsImage: {}
                //     },
                //     iconStyle: {
                //         borderColor: "#B9B8CE",
                //         color: "none"
                //     }
                // },
                dataZoom: [
                    {
                        show: true,
                        realtime: true,
                        start: 0,
                        end: 100,
                        xAxisIndex: [0, 1]
                    }, {
                        type: "inside",
                        realtime: true,
                        start: 0,
                        end: 100,
                        xAxisIndex: [0, 1]
                    }
                ],
                xAxis: {
                    type: "category",
                    boundaryGap: false,
                    axisLine: { onZero: true },
                    data: this.timeData
                },
                yAxis: [
                    {
                        type: "value",
                        name: "Equity, Balance",
                        scale: true,
                        axisLabel: {
                            formatter: function (value, index) {
                                var value;
                                if (Math.abs(value) >= 1000) {
                                    value = value / 1000 + "k";
                                } else if (value < 1000) {
                                    value = value;
                                }
                                return value;
                            }
                        },
                        splitNumber: 5,
                        position: "left",
                        axisLine: {
                            show: true
                        },
                        splitLine: {
                            show: false
                        },
                        max: function (value) {
                            if (Math.abs(value.max) > Math.abs(value.min)) {
                                let bite = 0;
                                let val = Math.abs(value.max) * 1.2;
                                if (val < 10) {
                                    return 10;
                                }
                                while (val >= 10) {
                                    val /= 10;
                                    bite += 1;
                                }
                                return Math.ceil(val) * Math.pow(10, bite);
                            } else {
                                let bite = 0;
                                let val = Math.abs(value.min) * 1.2;
                                if (val < 10) {
                                    return 10;
                                }
                                while (val >= 10) {
                                    val /= 10;
                                    bite += 1;
                                }
                                return Math.ceil(val) * Math.pow(10, bite);
                                // return (Math.abs(value.min)*1.2).toFixed(2);
                            }
                        },
                        min: function (value) {
                            if (Math.abs(value.max) > Math.abs(value.min)) {
                                let bite = 0;
                                let val = Math.abs(value.max) * 1.2;
                                if (val < 10) {
                                    return 10;
                                }
                                while (val >= 10) {
                                    val /= 10;
                                    bite += 1;
                                }
                                return -Math.ceil(val) * Math.pow(10, bite);
                            } else {
                                let bite = 0;
                                let val = Math.abs(value.min) * 1.2;
                                if (val < 10) {
                                    return 10;
                                }
                                while (val >= 10) {
                                    val /= 10;
                                    bite += 1;
                                }
                                return -Math.ceil(val) * Math.pow(10, bite);
                            }
                        }
                    },
                    {
                        type: "value",
                        splitNumber: 3,
                        // inverse: true,
                        name: "DPM",
                        offset: 10,
                        position: "right",
                        axisLine: {
                            show: true
                        },
                        splitLine: {
                            show: false
                        },
                        max: function (value) {
                            if (Math.abs(value.max) > Math.abs(value.min)) {
                                let bite = 0;
                                let val = Math.abs(value.max) * 1.2;
                                if (val < 10) {
                                    return 10;
                                }
                                while (val >= 10) {
                                    val /= 10;
                                    bite += 1;
                                }
                                return Math.ceil(val) * Math.pow(10, bite);
                            } else {
                                let bite = 0;
                                let val = Math.abs(value.min) * 1.2;
                                if (val < 10) {
                                    return 10;
                                }
                                while (val >= 10) {
                                    val /= 10;
                                    bite += 1;
                                }
                                return Math.ceil(val) * Math.pow(10, bite);
                                // return (Math.abs(value.min)*1.2).toFixed(2);
                            }
                        },
                        min: function (value) {
                            if (Math.abs(value.max) > Math.abs(value.min)) {
                                let bite = 0;
                                let val = Math.abs(value.max) * 1.2;
                                if (val < 10) {
                                    return 10;
                                }
                                while (val >= 10) {
                                    val /= 10;
                                    bite += 1;
                                }
                                return -Math.ceil(val) * Math.pow(10, bite);
                            } else {
                                let bite = 0;
                                let val = Math.abs(value.min) * 1.2;
                                if (val < 10) {
                                    return 10;
                                }
                                while (val >= 10) {
                                    val /= 10;
                                    bite += 1;
                                }
                                return -Math.ceil(val) * Math.pow(10, bite);
                            }
                        }
                    },
                    {
                        type: "value",
                        name: "Daily PNL",
                        splitNumber: 3,
                        // boundaryGap: [0, 100],
                        // inverse: true
                        position: "right",
                        offset: 60,
                        axisLine: {
                            show: true
                        },
                        splitLine: {
                            show: false
                        },
                        max: function (value) {
                            if (Math.abs(value.max) > Math.abs(value.min)) {
                                let bite = 0;
                                let val = Math.abs(value.max) * 1.2;
                                if (val < 10) {
                                    return 10;
                                }
                                while (val >= 10) {
                                    val /= 10;
                                    bite += 1;
                                }
                                return Math.ceil(val) * Math.pow(10, bite);
                            } else {
                                let bite = 0;
                                let val = Math.abs(value.min) * 1.2;
                                if (val < 10) {
                                    return 10;
                                }
                                while (val >= 10) {
                                    val /= 10;
                                    bite += 1;
                                }
                                return Math.ceil(val) * Math.pow(10, bite);
                                // return (Math.abs(value.min)*1.2).toFixed(2);
                            }
                        },
                        min: function (value) {
                            if (Math.abs(value.max) > Math.abs(value.min)) {
                                let bite = 0;
                                let val = Math.abs(value.max) * 1.2;
                                if (val < 10) {
                                    return 10;
                                }
                                while (val >= 10) {
                                    val /= 10;
                                    bite += 1;
                                }
                                return -Math.ceil(val) * Math.pow(10, bite);
                            } else {
                                let bite = 0;
                                let val = Math.abs(value.min) * 1.2;
                                if (val < 10) {
                                    return 10;
                                }
                                while (val >= 10) {
                                    val /= 10;
                                    bite += 1;
                                }
                                return -Math.ceil(val) * Math.pow(10, bite);
                            }
                        }
                    }
                ],
                series: this.seriesData,
                darkMode: true,
                color: [
                    "#4992ff",
                    "#7cffb2",
                    "#fddd60",
                    "#ff6e76",
                    "#58d9f9",
                    "#05c091",
                    "#ff8a45",
                    "#8d48e3",
                    "#dd79ff"
                ],
                backgroundColor: "#1e1e1e",
                textStyle: {
                    color: "#B9B8CE"
                }
            }
        };
    },
    methods: {
        init() {
            const self = this;
            if (
                self.chart != null &&
                self.chart != "" &&
                self.chart != undefined
            ) {
                self.chart.dispose();
            }
            self.$nextTick(() => {
                self.chart = echarts.init(document.getElementById(self.dom));
                self.chart.setOption(self.option);
                // window.addEventListener("resize", function () {
                //     self.chart.resize();
                // });
            });
        },
        beforeDestroy () {
            if (this.chart) {
                this.chart.clear();
            }
        }
    },
    mounted () {
        this.init();
    },
    watch: {
        // timeData (nv, ov) {
        //     this.option.xAxis.data = nv;
        //     if (this.chart) {
        //         this.chart.clear();
        //     }
        //     this.init();
        // },
        // seriesData (nv, ov) {
        //     this.option.series = nv;
        //     if (this.chart) {
        //         this.chart.clear();
        //     }
        //     this.init();
        // },
        // loginValue (nv, ov) {
        //     this.option.title.text = nv + " History";
        //     if (this.chart) {
        //         this.chart.clear();
        //     }
        // },
        // legendData (nv, ov) {
        //     this.option.legend.data = nv;
        //     if (this.chart) {
        //         this.chart.clear();
        //     }
        //     this.init();
        // }
    }
};
</script>
