import { mapState } from "vuex";

export const alert = {
    computed: {
        ...mapState(["alertNotificationShowItem", "disalbeNotificationAlert"])
    },
    methods: {
        cacheLoad (data) {

        },
        cacheSave (data) {
            const key = "alertCache:" + data[0].broker + ":" + data[0].alert_name;

            if (localStorage.getItem(key) == null || localStorage.getItem(key) == undefined) {
                const localData = data;
                localStorage.setItem(key, JSON.stringify(localData));
            } else {
                const localData = JSON.parse(localStorage.getItem(key));
                localData.unshift(data[0]);
                localStorage.setItem(key, JSON.stringify(localData));
            }
            this.$store.commit("alertCount");
        },
        /**
		 * Remove some unused channel
		 */
        filterChannel (data) {
            if (this.disalbeNotificationAlert.includes(data)) return true;

            return false;
        },
        alertPermissionDataFilter (data) {
            const permission = JSON.parse(localStorage.getItem("bp"));
            const alertBookExcept = permission.frontPermission.alert.bookExcept;

            // let result = data.filter(item => (!alertBookExcept.includes(item.book)));

            return data;
            // return result;
        },
        alertPermissionCheck (data) {
            let book = "";
            const alert_name = data.alert_name;

            if (alert_name == "tick_check") return false;
            book = data.book;

            // if (alert_name == "big_lot" || alert_name == "profit_taker" ||
            // 	alert_name == "locking_position" || alert_name == "large_exposure") {
            // 	book = data.book;
            // } else if (alert_name == "mandate") {
            // 	book = data.bookOrGoup;
            // } else if (alert_name == "trade_on_credit") {
            // 	return false
            // }

            const bookHide = JSON.parse(localStorage.getItem("alertHide"));

            // let permission = JSON.parse(localStorage.getItem("bp"));
            // let alertBookExcept = permission["frontPermission"]["alert"]["bookExcept"];

            if (bookHide.length == 0) return true;
            if (book == null) return true;
            if (bookHide.includes(book)) return false;

            return true;
        },
        alertGlobalSwitch () {
            const data = localStorage.getItem("globalNotificationAlert");

            if (data == "true") return true;
            return false;
        },
        alertMsg (alertName, data) {
            let msg = " " + data[0].alert_name.replace("_", " ").toUpperCase() + " - " + data[0].broker.replace("_", " ").toUpperCase() + "\n";
            let count = 0;
            for (const item in data[0]) {
                if (this.alertNotificationShowItem[alertName][item]) {
                    let displayValue = data[0][item];

                    if (!isNaN(data[0][item]) && item != "login" && item != "order" && item != "orderTicket") {
                        displayValue = displayValue.toFixed(2);
                    }

                    msg += " " + item.replace("_", "").toUpperCase() + ": " + displayValue;

                    if (count % 2 == 0) {
                        msg += "\n";
                    }
                    count += 1;
                }
            }
            return msg;
        },
        /**
		 * 用户自定义过滤规则主逻辑
		 * @param  {[type]} rule  [过滤规则]
		 * @param  {[type]} alert [警报名字]
		 * @param  {[type]} data  [数据]
		 * @return {[type]}       [description]
		 */
        alertGlobalFilterFrontend (rule, alert, data) {
            let result = data;

            if (alert == "big_lot") {
                result = data.filter(item => parseFloat(Math.abs(item["volume"])) >= parseFloat(rule.volume));
            } else if (alert == "mandate") {
                // console.log("Mandate Rule: ", rule);
                const dataLots = result.filter(item => item.type == "LOTS").filter(item => (parseFloat(Math.abs(item.currentValue)) >= parseFloat(rule.lots)));
                const dataPnl = result.filter(item => item.type == "PNL").filter(item => (parseFloat(Math.abs(item.currentValue)) >= parseFloat(rule.pnl)));
                Array.prototype.push.apply(dataLots, dataPnl);
                result = dataLots;
            } else if (alert == "profit_taker") {
                result = data.filter(item => (parseFloat(Math.abs(item.profitToday)) >= parseFloat(rule.profitToday)));
            } else if (alert == "locking_position") {
                console.log("==========");
                console.log(result[0]);
                console.log("==========");
                result = data.filter(item => (parseFloat(Math.abs(item.lockingPosition)) >= parseFloat(rule.lockingPosition)));
            } else if (alert == "trade_on_credit") {
                result = data.filter(item =>
                    (parseFloat(Math.abs(item.equity)) >= parseFloat(rule.equity) && parseFloat(item.credit) >= parseFloat(rule.credit))
                );
            } else if (alert == "large_exposure") {
                result = data.filter(item => (parseFloat(Math.abs(item.exposure)) >= parseFloat(rule.exposure)));
            } else if (alert == "fast_trade") {
                result = data.filter(item => (parseFloat(Math.abs(item.volume)) >= parseFloat(rule.volume)));
            } else if (alert == "watch_list") {
                // let loginFilter = rule.logins.split(",");
                // if(loginFilter!=""){
				    // result = data.filter(item => (loginFilter.includes(item.login.toString())));}
            } else if (alert == "same_direction") {
                result = data.filter(item => (parseFloat(Math.abs(item.totalVolume)) >= parseFloat(rule.totalVolume)));
            } else if (alert == "volume_alert") {
                result = data.filter(item => (parseFloat(Math.abs(item.volumeToday)) >= parseFloat(rule.volumeToday)));
            }

            return result;
        },
        formatAlertName(name) {
            if (name == "tick_check") return "Tick Check";
            if (name == "big_lot") return "Big Lot";
            if (name == "mandate") return "Mandate";
            if (name == "profit_taker") return "Profit Taker";
            if (name == "locking_position") return "Locking Position";
            if (name == "large_exposure") return "Large Exposure";
            if (name == "trade_on_credit") return "Trade On Credit";
            if (name == "fast_trade") return "Fast Trade";
            if (name == "watch_list") return "Watchlist";
            if (name == "tick_check") return "Tick Check";
            if (name == "same_direction") return "Same Direction";
            if (name == "volume_alert") return "Volume Alert";
            if (name == "withdraw_deposit") return "Withdraw Deposit";
        },
        /**
         * To be checked when user login to site
         *
         * @param   {[type]}  headersAjax  [headersAjax description]
         * @param   {[type]}  headerStore  [headerStore description]
         *
         * @return  {[type]}               [return description]
         */
        checkHeaders(headersAjax) {
            
            // let result = headersAjax;
            // data.layout_alert.alertHeader
            let headerStore = this.$store.state.alertHeader;

            // check if alert header missing in ajax data
            for (let alert in headersAjax.alertHeader) {
                if (Object.prototype.hasOwnProperty.call(headerStore, alert) && Object.prototype.hasOwnProperty.call(headersAjax.alertHeader, alert)) {

                    let alertHeaderStore = headerStore[alert]
                    let alertHeaderAjax = headersAjax.alertHeader[alert]

                    for (let a = 0; a < alertHeaderStore.length; a++) {
                        let find = false;
                        for (let i = 0; i < alertHeaderAjax.length; i++) {
                            if (alertHeaderStore[a].value === alertHeaderAjax[i].value) {
                                find = true;
                                break;
                            }
                        }

                        if (!find) {
                            headersAjax.alertHeader[alert].push(alertHeaderStore[a]);
                        }
                    }
                }
            }
            
            let index = [];

            // check remove index
            for (let alert in headersAjax.alertHeader) {

                if (Object.prototype.hasOwnProperty.call(headerStore, alert) && Object.prototype.hasOwnProperty.call(headersAjax.alertHeader, alert)) {
                    let alertHeaderStore = headerStore[alert]
                    let alertHeaderAjax = headersAjax.alertHeader[alert]

                    for (let a = 0; a < alertHeaderAjax.length; a++) {
                        let find = false;

                        for (let i = 0; i < alertHeaderStore.length; i++) {
                            if (alertHeaderAjax[a].value === alertHeaderStore[i].value) {
                                find = true;
                                break;
                            }
                        }

                        if (!find) {
                            index.push(a);
                        }
                    }
                }

                if (index.length !== 0) {
                    let indexSorted = index.sort((a, b) => {
                        return b - a;
                    })
                    
                    indexSorted.map(item => {
                        headersAjax.alertHeader[alert].splice(item, 1);
                    })
                }
            }

            return headersAjax;
        },
        checkHighlight (tableData, highlight) {
            if (highlight == null || highlight == undefined) return false;
            if (!highlight.hasOwnProperty("data")) false;
            const highlightData = highlight.data;
            if (tableData == null || tableData == undefined) return false;
            if (highlightData == null || highlightData == undefined) return false;
            const alertName = highlightData.alert_name;

            if (alertName == "big_lot") {
                if (tableData.order == highlightData.order &&
					tableData.symbol == highlightData.symbol &&
					tableData.login == highlightData.login &&
					tableData.time == highlightData.time) {
                    return true;
                }
            }

            if (alertName == "mandate") {
                if (tableData.symbol == highlightData.symbol &&
					tableData.book == highlightData.book &&
					tableData.time == highlightData.time) {
                    return true;
                }
            }

            if (alertName == "profit_taker") {
                if (tableData.symbol == highlightData.symbol &&
					tableData.book == highlightData.book &&
					tableData.time == highlightData.time &&
					tableData.profitToday == highlightData.profitToday) {
                    return true;
                }
            }

            if (alertName == "locking_position") {
                if (tableData.symbol == highlightData.symbol &&
					tableData.book == highlightData.book &&
					tableData.time == highlightData.time &&
					tableData.lockingPosition == highlightData.lockingPosition) {
                    return true;
                }
            }

            if (alertName == "trade_on_credit") {
                if (tableData.book == highlightData.book &&
					tableData.login == highlightData.login &&
					tableData.time == highlightData.time &&
					tableData.balance == highlightData.balance &&
					tableData.equity == highlightData.equity &&
					tableData.credit == highlightData.credit) {
                    return true;
                }
            }

            if (alertName == "large_exposure") {
                if (tableData.symbol == highlightData.symbol &&
					tableData.login == highlightData.login &&
					tableData.time == highlightData.time &&
					tableData.exposure == highlightData.exposure) {
                    return true;
                }
            }

            if (alertName == "fast_trade") {
                if (tableData.symbol == highlightData.symbol &&
					tableData.login == highlightData.login &&
					tableData.time == highlightData.time &&
					tableData.book == highlightData.book &&
					tableData.volume == highlightData.volume &&
					tableData.ticket == highlightData.ticket) {
                    return true;
                }
            }

            if (alertName == "watch_list") {
                if (tableData.direction == highlightData.direction &&
					tableData.login == highlightData.login &&
					tableData.time == highlightData.time &&
					tableData.symbol == highlightData.symbol &&
					tableData.price == highlightData.price &&
					tableData.orderTicket == highlightData.orderTicket &&
					tableData.book == highlightData.book) {
                    return true;
                }
            }

            if (alertName == "same_direction") {
                if (tableData.book == highlightData.book &&
					tableData.login == highlightData.login &&
					tableData.time == highlightData.time &&
					tableData.symbol == highlightData.symbol &&
					tableData.volume == highlightData.volume &&
					tableData.order == highlightData.order &&
					tableData.event == highlightData.event) {
                    return true;
                }
            }

            if (alertName == "volume_alert") {
                if (tableData.book == highlightData.book &&
					tableData.login == highlightData.login &&
					tableData.time == highlightData.time &&
					tableData.amount == highlightData.amount &&
					tableData.order == highlightData.order &&
					tableData.type == highlightData.type) {
                    return true;
                }
            }

            if (alertName == "withdraw_deposit") {
                if (tableData.book == highlightData.book &&
					tableData.login == highlightData.login &&
					tableData.time == highlightData.time &&
					tableData.amount == highlightData.amount &&
					tableData.order == highlightData.order &&
					tableData.type == highlightData.type) {
                    return true;
                }
            }

            return false;
        }
    }
};
