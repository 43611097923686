<template>
    <v-card class="elevation-0">
        <v-list-item one-line>
            <v-list-item-content>
                <div class="overline">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <!-- <span v-on="on"> -->
                                <!-- <v-icon small color="white" class="mr-2" left>
                                    mdi-help
                                </v-icon> -->
                            <!-- </span><span>{{ name }}</span> -->
                            <span v-bind="attrs" v-on="on"> {{name}} </span>
                        </template>
                        <span>{{description}}</span>
                    </v-tooltip>
                    <v-list-item-title >
                        <v-icon dense class="pb-1" :color="color" small>
                            {{color === 'red' ?
                            "mdi-arrow-down-bold" :  color==='green' ? "mdi-arrow-up-bold" : ''}}
                        </v-icon>
                        {{ value }}
                    </v-list-item-title>
                </div>
            </v-list-item-content>
        </v-list-item>
        <!-- <v-list-item>
            <v-list-item-title>{{ value }}</v-list-item-title>
            <v-list-item-icon>
                <v-icon>
                    mdi-arrow-up
                </v-icon>
            </v-list-item-icon>
        </v-list-item> -->
    </v-card>
</template>
<script>
export default {
    props: {
        cols: { type: Number, default: 4 },
        name: { type: String, default: "" },
        value: { type: String, default: "-1" },
        description: { type: String, default: "-1" }
    },
    data () {
        return {
            color: "",
            initialvalue: 0,
            compare: Object
        };
    },
    created () {},
    mounted () {
        // console.log(this.value)
        this.compare = setInterval(() => {
            // console.log(this.value, typeof(this.value))
            if (parseFloat(this.value) > parseFloat(this.initialvalue)) {
                this.color = "red";
            } else if (parseFloat(this.value) < parseFloat(this.initialvalue)) {
                this.color = "green";
            } else {
                this.color = "";
            }
            this.initialvalue = this.value;
        }, 900);
    },
    destroyed () {
        clearInterval(this.compare);
    }
};
</script>
