export const desktopNotification = {
    methods: {
        deskNotify (title, message) {
            if (Notification.permission != "granted") {
                Notification.requestPermission();
            } else {
                new Notification(title, {
                    body: message
                });
            }
        }
    }
};
