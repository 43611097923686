
import request from "@utils/pk/request";

export function fetch (query) {
    return request({
        url: "oz/percentage-controlled-warehousing-login",
        method: "get",
        params: query
    });
}
