<template>
    <div class="home">
        <v-card>
            <!-- <GlobalNotificationBar :selectedBrokerId="selectedBrokerId" :bookList="listBook" v-if="loadGlobalNotificationBar === 1" /> -->

            <v-container fluid>
                <v-row><v-col cols="12" class="mb-0"></v-col></v-row>
                <div v-if="loadPk === 1">
                    <v-row><v-col cols="12"></v-col></v-row>
                    <v-row dense>
                        <v-col lg="7" md="7" xs="12" cols="12">
                            <v-row dense>
                                <v-col lg="12" md="12" xs="12" cols="12">
                                    <v-card outlined>
                                        <v-card-title style="border-bottom: 1px solid #404040;" class="pt-2 pb-2">
                                            <div class="text-left" style="margin-left: 0; width: 50%; font-size: 16px; font-weight: normal;">
                                                MONITOR
                                            </div>
                                            <div class="text-right" style="margin-right: 0; width: 50%;">
                                                <div style="display: inline" class="mr-2">
                                                    <v-icon color="red" dark dense @click="togglePkTableFilter">
                                                        mdi-filter
                                                    </v-icon>
                                                </div>
                                                <!-- CSV导出 -->
                                                <download-excel :data="newObject" :fields="csvFields"  style="display: inline"
                                                    :name="selectedBroker + '_position_' + selectedBook">
                                                    <v-icon color="green" dark dense>
                                                        mdi-microsoft-excel
                                                    </v-icon>
                                                </download-excel>
                                            </div>
                                        </v-card-title>

                                        <v-row align="center" >
                                            <v-col cols="3" class="pl-6">
                                                <v-menu offset-y v-if="root">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn color="warning" dark v-bind="attrs" v-on="on">{{ selectedBroker }}</v-btn>
                                                    </template>
                                                    <v-list>
                                                        <v-list-item v-for="(item, index) in this.brokers" :key="index"
                                                            @click="changeBroker(item.broker, item.broker_id)">
                                                            <v-list-item-title>{{ item.broker }}</v-list-item-title>
                                                        </v-list-item>
                                                    </v-list>
                                                </v-menu>
                                                <v-menu offset-y>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn color="primary" v-bind="attrs" v-on="on" class="ml-0">{{ selectedBook }} BOOK</v-btn>
                                                    </template>
                                                    <v-list>
                                                        <v-list-item v-for="(item, index) in listBook" :key="index"
                                                            @click="changeBook(item.book)">
                                                            <v-list-item-title>{{ item.book }}</v-list-item-title>
                                                        </v-list-item>
                                                    </v-list>
                                                </v-menu>
                                            </v-col>

                                            <v-col cols="3" align="center">
                                                <CardHead :name="'Unrealised'" :value="newSummary.FPNL.toFixed(2)" :description="'(Last Quote - EOD Price) * Volume, the End-of-Day Price is updated at 00:00:01 AM server time'" />
                                            </v-col>
                                            <v-col cols="3" align="center">
                                                <CardHead :name="'Realised'" :value="newSummary.CPNL.toFixed(2)" :description="'Data extracted from MT4 server for Order Close Time 00:00:00 23:59:59 on currency day.'" />
                                            </v-col>
                                            <v-col cols="3" align="center">
                                                <CardHead :name="'Daily Net'" :value="newSummary.NPNL.toFixed(2)" :description="'Unrealised (EOD) + Realised'" />
                                            </v-col>
                                        </v-row>
                                        <v-divider></v-divider>
                                        <v-row class="mb-1 mt-4 pl-4" v-if="showPkTableFilter">
                                            <v-checkbox class="home-checkbox mr-1 ml-1 mb-0 mt-0" dense
                                                v-model="item.align === 'left'" :label="item.text"
                                                v-for="item in headers" :key="item.value"
                                                v-on:click="checkHeader(item)"></v-checkbox>
                                        </v-row>

                                        <!-- PK主表逻辑 -->
                                        <v-data-table dense :search="search" :headers="headers" :items="filteredDataSource"
                                            :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :custom-sort="customSort"
                                            :header-props="{ sortIcon: null }"
                                            :custom-filter="filterTable" :single-expand="true" :expanded.sync="expanded"
                                            item-key="symbol" @click:row="rowClicked" class="elevation-0"
                                            :items-per-page="itemsPerPage" id="home-table" height="576"
                                            :hide-default-footer="true" fixed-header>

                                            <!-- 表头 -->
                                            <template v-for="h in headers" v-slot:[`header.${h.value}`]="{ header }">
                                                <v-tooltip top >
                                                    <template v-slot:activator="{ on }">
                                                        <!-- <span v-on="on" v-on:mouseover="showHeaderFilter(h)">{{h.text}}</span> -->
                                                        <span v-on="on">{{h.text}}</span>
                                                    </template>
                                                    <span>{{ h.description }}</span>
                                                </v-tooltip>
                                                <v-menu offset-y :close-on-content-click="false" top
                                                    v-if="h.showFilter && tableFilter.hasOwnProperty(h.value)">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn icon v-bind="attrs" v-on="on" style="left: 0px">
                                                            <v-icon x-small>mdi-filter</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <v-text-field v-model="tableFilter.symbol" dense class="pt-6 pl-3 pr-3 pb-3" type="text"
                                                        v-if="h.value === 'symbol'" label="Enter Symbol"
                                                        style="width: 100%; background-color: #1e1e1e;" />
                                                    <v-text-field v-model="tableFilter.pb_long" dense class="pt-6 pl-3 pr-3 pb-3" type="text"
                                                        v-if="h.value === 'pb_long'" label="Enter Volume >=" style="width: 100%; background-color: #1e1e1e;" />
                                                    <v-text-field v-model="tableFilter.pb_short" dense class="pt-6 pl-3 pr-3 pb-3" type="text"
                                                        v-if="h.value === 'pb_short'" label="Enter Volume >=" style="width: 100%; background-color: #1e1e1e;" />
                                                    <v-text-field v-model="tableFilter.netPnl" dense class="pt-6 pl-3 pr-3 pb-3" type="text"
                                                        v-if="h.value === 'netPnl'" label="Enter PNL >=" style="width: 100%; background-color: #1e1e1e;" />
                                                    <v-text-field v-model="tableFilter.netPosition" dense class="pt-6 pl-3 pr-3 pb-3" type="text"
                                                        v-if="h.value === 'netPosition'" label="Enter Net Position >=" style="width: 100%; background-color: #1e1e1e;" />
                                                </v-menu>
                                            </template>

                                            <!-- PK表column的filter -->

                                            <!-- PK表鼠标点击后的副表 -->
                                            <template v-slot:expanded-item="{ headers, item }">
                                                <td :colspan="headers.length">
                                                    <!-- PK副标的搜索 -->
                                                    <v-text-field class="mr-4 mt-n2 ml-4 1mb-5" v-model="searchLogin"
                                                        append-icon="mdi-search" label="Search Login" single-line
                                                        hide-details>
                                                    </v-text-field>

                                                    <!-- PK表附表逻辑 -->
                                                    <v-data-table dense :search="searchLogin" :headers="loginDetailHeaders"
                                                        :items="symbolDetailObject" v-if="loadSubTable" item-key="login"
                                                        :items-per-page="-1" fixed-header :height="loginDetailHeight"
                                                        :hide-default-footer="true">

                                                        <template v-slot:item="item">
                                                            <tr>
                                                                <td>
                                                                    <v-tooltip top>
                                                                        <template v-slot:activator="{ on, attrs }">
                                                                            <span v-bind="attrs" v-on="on"
                                                                                class="hover-login-tooltip"
                                                                                v-on:click="goToTag(item.item.login)"
                                                                                v-on:mouseover="loginDetailHandler().load(item.item.login)"
                                                                                v-on:mouseleave="loginDetailHandler().remove()">
                                                                                {{ item.item.login }}
                                                                            </span>
                                                                        </template>
                                                                        <span v-html="formatToolTip(loginDetail)"></span>
                                                                    </v-tooltip>
                                                                </td>
                                                                <td>
                                                                    {{ item.item.volume_long.toFixed(2) }}
                                                                </td>
                                                                <td>
                                                                    {{ item.item.volume_short.toFixed(2) }}
                                                                </td>
                                                                <td>
                                                                    {{ item.item.volume_net.toFixed(2) }}
                                                                </td>
                                                                <td>
                                                                    <span
                                                                        v-if="openTradesSummary.hasOwnProperty(item.item.login.toString())
                                                                        && openTradesSummary[item.item.login.toString()].hasOwnProperty('average_buy')">
                                                                        {{ openTradesSummary[item.item.login.toString()].average_buy }}
                                                                    </span>
                                                                    <span v-if="!(openTradesSummary.hasOwnProperty(item.item.login.toString())
                                                                        && openTradesSummary[item.item.login.toString()].hasOwnProperty('average_buy'))">
                                                                        0
                                                                    </span>
                                                                </td>
                                                                <td>
                                                                    <span
                                                                        v-if="openTradesSummary.hasOwnProperty(item.item.login.toString())
                                                                        && openTradesSummary[item.item.login.toString()].hasOwnProperty('average_sell')">
                                                                        {{ openTradesSummary[item.item.login.toString()].average_sell }}
                                                                    </span>
                                                                    <span v-if="!(openTradesSummary.hasOwnProperty(item.item.login.toString())
                                                                        && openTradesSummary[item.item.login.toString()].hasOwnProperty('average_sell'))">
                                                                        0
                                                                    </span>
                                                                </td>
                                                                <td>
                                                                    <span v-if="configPercentContorl.login.hasOwnProperty(item.item.login)">
                                                                        Yes - {{ configPercentContorl.login[item.item.login]['s'] }}%
                                                                    </span>
                                                                    <span v-if="!configPercentContorl.login.hasOwnProperty(item.item.login)">
                                                                        No - 0%
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        </template>
                                                    </v-data-table>
                                                </td>
                                            </template>

                                            <template v-slot:item="item">
                                                <tr v-on:click="rowClicked(item.item)">
                                                    <td v-if="checkHeaderActive('symbol')" >

                                                        <v-tooltip top>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-btn elevation="0" plain style="background: transparent; padding-left: 0px;"
                                                                    small dark v-bind="attrs" v-on="on">
                                                                    {{ item.item.symbol }}
                                                                </v-btn>
                                                            </template>
                                                            <!-- <span v-html="extractEodPrice(item.item.eodPrice, configPercentContorl.symbol.includes(item.item.symbol))">{{ item.item.eodPrice }}</span> -->
                                                            <span v-html="extractEodPrice(item.item.eodPrice, false)">{{ item.item.eodPrice }}</span>
                                                        </v-tooltip>

                                                        <!-- <a class="symbol-detail"
                                                            v-if="configPercentContorl.symbol.includes(item.item.symbol)">
                                                            <v-icon color="blue" dark small>mdi-alpha-h-circle-outline</v-icon>
                                                        </a> -->
                                                    </td>
                                                    <td v-if="checkHeaderActive('pb_long')"><span>{{ roundDecimal(item.item.pbLong, 2) }}</span></td>
                                                    <td v-if="checkHeaderActive('pb_short')"><span>{{ roundDecimal(item.item.pbShort, 2) }}</span></td>
                                                    <td v-if="checkHeaderActive('netPosition')"
                                                        v-bind:class="{'td-alert': Math.abs(item.item.netPosition) >= 30 && !stockList.includes(item.item.symbol) }">
                                                        {{ roundDecimal(item.item.netPosition, 2) }}
                                                    </td>

                                                    <td v-if="checkHeaderActive('netPnl')"
                                                        v-bind:class="{'td-alert': Math.abs(item.item.netPnl) >= 20000 && !stockList.includes(item.item.symbol)}">
                                                        {{ roundDecimal(item.item.netPnl, 2) }}
                                                    </td>
                                                    <td v-if="checkHeaderActive('hg_net')"><span>{{ outputHedge(item, hedgeObject)["net"].toFixed(2) }}</span></td>
                                                    <td v-if="checkHeaderActive('hg_float')"><span>{{ outputHedge(item, hedgeObject).netPnl.toFixed(2) }}</span></td>
                                                    <td v-if="checkHeaderActive('floatingPnl')"
                                                        v-bind:class="{
                                                            'td-down': compares[item.item.symbol].floatingPnl === 'red',
                                                            'td-up': compares[item.item.symbol].floatingPnl === 'green'
                                                        }">
                                                        <!-- <v-icon  :color="compares[item.item.symbol].floatingPnl" small>
                                                            {{compares[item.item.symbol].floatingPnl === 'red' ?
                                                            "mdi-arrow-down-bold" :  compares[item.item.symbol].floatingPnl==='green' ? "mdi-arrow-up-bold" : 'mdi-minus'}}
                                                        </v-icon> -->
                                                        {{ roundDecimal(item.item.floatingPnl, 2) }}
                                                    </td>
                                                    <td v-if="checkHeaderActive('floatingPnlMt4')">
                                                        {{ roundDecimal(item.item.floatingPnlMt4, 2) }}
                                                    </td>
                                                    <td v-if="checkHeaderActive('closedPnl')">
                                                        <!-- <v-icon v-if="showClosePnlDialog === 1" small color="white" class="mr-2" left
                                                            @click.stop="showCloseSummaryDialog(item.item)">
                                                            mdi-alert-octagon-outline
                                                        </v-icon> -->
                                                        <span class="hoverable-close" v-if="showClosePnlDialog === 1" @click.stop="showCloseSummaryDialog(item.item)">
                                                            {{ roundDecimal(item.item.closedPnl, 2) }}
                                                        </span>
                                                        <span class="hoverable-close" v-if="showClosePnlDialog != 1">
                                                            {{ roundDecimal(item.item.closedPnl, 2) }}
                                                        </span>
                                                    </td>

                                                    <td v-if="checkHeaderActive('avgBuyPrice')">
                                                        {{ getAveragePrice(item.item.totalSumLong, item.item.totalVolLong, item.item.digits) }}
                                                    </td>
                                                    <td v-if="checkHeaderActive('avgSellPrice')">
                                                        {{ getAveragePrice(item.item.totalSumShort, item.item.totalVolShort, item.item.digits) }}
                                                    </td>
                                                    <!-- <td v-if="checkHeaderActive('eod')">
                                                        <v-tooltip top>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-btn color="default" small dark v-bind="attrs" v-on="on">
                                                                    Eod
                                                                </v-btn>
                                                            </template>
                                                            <span v-html="extractEodPrice(item.item.eodPrice)">{{ item.item.eodPrice }}</span>
                                                        </v-tooltip>
                                                    </td> -->
                                                </tr>
                                            </template>
                                        </v-data-table>
                                    </v-card>

                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col lg="5" md="5" xs="12" cols="12">

                            <v-card outlined height="250px">
                                <v-card-title style="border-bottom: 1px solid #404040;" class="pt-2 pb-2">
                                    <div class="text-left" style="margin-left: 0; width: 50%; font-size: 16px; font-weight: normal;">
                                        ALL BOOKS
                                    </div>
                                </v-card-title>

                                <v-data-table
                                    dense
                                    :headers="allBooks.header" :items="filteredAllBookData" item-key="name"
                                    class="elevation-1" height="200px"
                                    :items-per-page="-1"
                                    :header-props="{ sortIcon: null }"
                                    fixed-header
                                    >

                                    <!-- 表头 -->
                                    <template v-slot:body.prepend>

                                    </template>
                                    <template v-slot:item="item">
                                        <tr>
                                            <td><a v-on:click="changeBook(item.item.book)">{{ item.item.book }}</a></td>
                                            <td>{{ item.item.NPNL.toFixed(2) }}</td>
                                            <td>{{ item.item.CPNL.toFixed(2) }}</td>
                                            <td
                                                v-bind:class="{
                                                    'td-down': allBookFPNLCompares[item.item.book].FPNL === 'red',
                                                    'td-up': allBookFPNLCompares[item.item.book].FPNL === 'green'
                                                }"
                                                v-if="allBookFPNLCompares.hasOwnProperty(item.item.book)"
                                                >
                                                {{ item.item.FPNL.toFixed(2) }}
                                            </td>
                                            <td>
                                                <span v-if="!chartExposureLargestProduct.hasOwnProperty(item.item.book)">None</span>
                                                <span v-if="chartExposureLargestProduct[item.item.book] === '' &&
                                                    chartExposureLargestProduct.hasOwnProperty(item.item.book)">
                                                    None
                                                </span>
                                                <span v-if="chartExposureLargestProduct[item.item.book] != '' &&
                                                    Object.prototype.hasOwnProperty.call(chartExposureLargestProduct, item.item.book)">
                                                    {{ chartExposureLargestProduct[item.item.book] }}
                                                </span>
                                            </td>
                                            <td>
                                                <span v-if="!Object.prototype.hasOwnProperty.call(bookVolumeUsd, item.item.book)">0</span>
                                                <span v-if="Object.prototype.hasOwnProperty.call(bookVolumeUsd, item.item.book)">
                                                    {{ (bookVolumeUsd[item.item.book]/1000000).toFixed(2) }} M
                                                </span>
                                            </td>
                                            <td>
                                                <span v-if="!Object.prototype.hasOwnProperty.call(chartMtdPnl, item.item.book)">0</span>
                                                <span v-if="Object.prototype.hasOwnProperty.call(chartMtdPnl, item.item.book)">
                                                    {{ chartMtdPnl[item.item.book].toFixed(2) }}
                                                </span>
                                            </td>
                                            <td>
                                                <span v-if="!Object.prototype.hasOwnProperty.call(chartMtdVol, item.item.book)">0</span>
                                                <span v-if="Object.prototype.hasOwnProperty.call(chartMtdVol, item.item.book)">
                                                    {{ (chartMtdVol[item.item.book]/1000000).toFixed(2) }} M
                                                </span>
                                            </td>

                                        </tr>
                                    </template>
                                </v-data-table>
                            </v-card>

                            <v-row v-if="loadExposure && initialChart">
                                <v-col lg="12" md="12" xs="12" cols="12">

                                    <v-card outlined>
                                        <v-card-title style="border-bottom: 1px solid #404040; " class="pt-2 pb-2">
                                            <div class="text-left" style="font-size: 16px; font-weight: normal; display:inline-block;">
                                                CCY EXPOSURE Top:
                                            </div>
                                            <v-select :items="topselction" dense class="pl-2 pt-2" id="CCY"
                                                style="display:block; float:right; max-width:60px;" v-model="topnumber"></v-select>
                                        </v-card-title>

                                        <ExposureChart :brokerId="selectedBrokerId" :book="selectedBook" :topnumber="topnumber"/>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </div>

                <!-- PK monitor table modal when click realized number -->
                <v-dialog v-model="dialogCloseSummaryVisible" :width="dialogWidth" :hide-overlay="true" :persistent="true">
                    <v-card>
                        <v-app-bar flat color="rgba(0, 0, 0, 0)" style="background: rgb(26, 57, 88);">
                            <v-toolbar-title class="text-h6 white--text pl-0">Close Summary - {{ closeSummarySymbol }}</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-btn color="white" icon @click="dialogCloseSummaryVisible = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-app-bar>

                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                    <v-tabs>
                                        <v-tab v-on:click="changeTab('login')">PNL by Login</v-tab>
                                        <v-tab v-on:click="changeTab('symbol')">PNL by Symbol</v-tab>
                                        <v-tab v-on:click="changeTab('raw')">Raw Order</v-tab>
                                    </v-tabs>
                                </v-col>
                            </v-row>
                            <v-row v-if="selectedKey === 'login'" class="mt-5">
                                <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                    <v-text-field v-model="searchCloseSummaryLogin" append-icon="mdi-magnify"
                                        label="Search" dense hide-details></v-text-field>
                                    <v-col></v-col>
                                    <v-data-table :headers="closeSummaryLoginHeaders" :items="closeSummaryLoginData"
                                        :search="searchCloseSummaryLogin" dense :items-per-page="-1">
                                    </v-data-table>
                                </v-col>
                            </v-row>
                            <v-row v-if="selectedKey === 'symbol'" class="mt-5">
                                <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                    <v-text-field v-model="searchCloseSummarySymbol" append-icon="mdi-magnify"
                                        label="Search" dense hide-details></v-text-field>
                                    <v-col></v-col>
                                    <v-data-table :headers="closeSummarySymbolHeaders" :items="closeSummarySymbolData"
                                        :search="searchCloseSummarySymbol" dense :items-per-page="-1">
                                    </v-data-table>
                                </v-col>
                            </v-row>
                            <v-row v-if="selectedKey === 'raw'" class="mt-5">
                                <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                    <v-text-field v-model="searchCloseSummaryRaw" append-icon="mdi-magnify"
                                        label="Search" dense hide-details />
                                    <v-col></v-col>
                                    <v-data-table :headers="closeSummaryRawHeaders" :items="closeSummaryRawData"
                                        :search="searchCloseSummaryRaw" dense>

                                    </v-data-table>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card>
                </v-dialog>

                <v-dialog v-model="chartDialog" height="500" width="1200" :hide-overlay="true" :persistent="true" >
                    <v-card>

                        <v-app-bar flat color="rgba(0, 0, 0, 0)" style="background: rgb(26, 57, 88);" >

                            <v-toolbar-title class="text-h6 white--text pl-0">{{ chartDialogTitle }}</v-toolbar-title>

                            <v-spacer></v-spacer>

                            <v-btn color="white" icon @click="chartDialogFullscreen = !chartDialogFullscreen">
                                <v-icon>mdi-arrow-expand</v-icon>
                            </v-btn>
                            <v-btn color="white" icon @click="chartDialog = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-app-bar>

                        <v-col cols="12">
                            <LoginHistoryChart dom="dom1" :timeData="LoginHistoryChartTimeDate" :loginValue="loginValue" :legendData="LoginHistoryChartLegendData" :seriesData="LoginHistoryChartSeriesData" />
                        </v-col>
                        <v-divider></v-divider>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" text @click="chartDialog = false">
                                Close
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-row>
                    <v-col cols="12"></v-col>
                </v-row>
            </v-container>
        </v-card>
        
        <!-- <v-btn color="warning" dark @click="clickDisconnect">{{ "disconnect" }}</v-btn> -->

    </div>
</template>
<script>
import moment from "moment";
import CardHead from "./home/components/ComponentCardHead";
import { mapActions, mapState } from "vuex";
import ExposureChart from "@components/ComponentExposureChart";
// import GlobalNotificationBar from "@components/ComponentGlobalNotificationBar";
// import { socket } from "@components/mixins/socket";
import { helper } from "@components/mixins/helper";
import { desktopNotification } from "@components/mixins/desktop-notification";
import { permission } from "@components/mixins/permission";
import { alert } from "@components/mixins/alert";
import { position } from "@components/mixins/position";
import { snackbar } from "@components/mixins/snackbar";
import * as apiTradesLoginSummary from "@components/api/pk/trades-login-summary";
import * as apiLoginHistory from "@components/api/pk/login-history";
import * as apiLoginDetail from "@components/api/pk/login-detail";
import * as apiOzPercentControlled from "@components/api/pk/oz/percent-controlled";
// import { io } from "socket.io-client";
import * as apiAlertChannel from "@components/api/pk/alert-channel";
// import * as apiSettingsBookRule from "@components/api/pk/settings/book-rule";
import * as apiSettingsUserAlertRule from "@components/api/pk/settings/user-alert-rule";
import * as apiTradesCloseSummary from "@components/api/pk/trades/close-summary";
import LoginHistoryChart from "@components/login-summary/ComponentLoginHistory";
// import LoginHistoryChart from "./home/components/ComponentLoginHistory";
import * as apiChartExposureLargestProduct from "@components/api/pk/chart/exposure-largest-product";
import * as apiChartMtdPnl from "@components/api/pk/chart/mtd-pnl";
import * as apiChartMtdVol from "@components/api/pk/chart/mtd-vol";
import * as apiLoadBookVolumeusd from "@components/api/pk/chart/book-volume-usd";

export default {
    name: "Home",
    components: {
        // GlobalNotificationBar,
        ExposureChart,
        LoginHistoryChart,
        CardHead
    },
    mixins: [helper, alert, position, permission, snackbar, desktopNotification],
    data () {
        return {
            snapShotLength : 10, // the total numbers of pervious data stored
            lossconnectionTimes: 3, // the times for determining the socket disconnected 
            lossconnectionCounter: 3, // === lossconnectionTimes, the counter for current time of sokcet disconnectred,
            intervalTimeForCheking: 2000, //ms 

            perviousSummary: [],
            perviousAllBookData: [],

            connectionBroken: false,
            loopIndex: 0,
            storeUnqiueNewObject: [],
            wachSocketReciving: null,
            perviousNewObject: null,
            wssUrlPk: "",
            pagination: {
            },
            allBookfilters: {
                book: { filtered: [], value: [] },
                lxp1: { filtered: [], value: [] },
                FPNL: { condition: "", value: 0 },
                CPNL: { condition: "", value: 0 },
                NPNL: { condition: "", value: 0 },
                vol: { condition: "", value: 0 },
                mtdPnl: { condition: "", value: 0 },
                mtdVol: { condition: "", value: 0 }

            },
            sortFPNL: false,
            inputFPNLfilter: 0,
            selectedBookInTable: [],
            topselction: ["All", 5, 10],
            wachAllBookFPNL: Object,
            allBokPevious: 0,
            allBookFPNLCompares: {},
            topnumber: 10,
            compares: {},
            bookVolumeUsd: {},
            chartExposureLargestProduct: {},
            chartMtdPnl: {},
            chartMtdVol: {},
            allBooks: {
                header: [
                    { text: "Book", value: "book", align: "left", width: 120, sortable: true },
                    { text: "NPNL", value: "NPNL", align: "left" },
                    { text: "CPNL", value: "CPNL", align: "left" },
                    { text: "FPNL", value: "FPNL", align: "left" },
                    { text: "LExp", value: "lxp1", align: "left" },
                    { text: "Volume", value: "vol", align: "left" },
                    { text: "MTD PNL", value: "mtdPnl", align: "left" },
                    { text: "MTD Vol", value: "mtdVol", align: "left" }
                ],
                data: []
            },
            configPercentContorl: {},
            tableFilter: {
                symbol: "", pb_long: 0, pb_short: 0, netPosition: 0, netPnl: 0
            },
            showPkTableFilter: false,
            initialChart: 0,
            // loadGlobalNotificationBar: 0,
            showLoginDetail: false,
            loginDetail: {},
            loadPk: 0,
            loadChart: 0,
            loadExposure: 0,
            openTradesSummary: {},
            openSymbol: "",
            selectedBroker: "",
            selectedBrokerId: 0,
            selectedBook: "",
            listBook: ["B", "GS", "H", "B1706", "Z"],
            newSummary: { L: 0, S: 0, N: 0, FPNL: 0, CPNL: 0, NPNL: 0 },
            ws: null,
            channel: "",
            sortByAllBooks: "book",
            sortDescAllBooks: false,

            sortBy: "symbol",
            sortDesc: false,
            chartDialog: false,
            chartDialogFullscreen: false,
            chartDialogTitle: "",
            csvHeader: [
                "Symbol", "Long", "Short",
                "NetPosition", "FloatingPNL(EOD)", "FloatingPNL(MT4)",
                "ClosedPNL", "NetPNL", "Avg Buy Price", "Avg Sell Price"
            ],
            csvFields: {
                Symbol: "symbol",
                Long: "pbLong",
                Short: "pbShort",
                NetPosition: "netPosition",
                "FloatingPNL(EOD)": "floatingPnl",
                ClosedPNL: "closedPnl",
                NetPNL: "netPnl",
                "Avg Buy Price": "avgLongPrice",
                "Avg Sell Price": "avgShortPrice"
            },
            expanded: [],
            loginDetailHeaders: [
                { text: "Login", value: "login" },
                { text: "Long", value: "volume_long" },
                { text: "Short", value: "volume_short" },
                { text: "Net", value: "volume_net" },
                { text: "Long VWAP", value: "avg_buy" },
                { text: "Short VWAP", value: "avg_sell" },
                { text: "Percent Controlled Warehouse", value: "controlledWearhouse", sortable: false },
                { text: "", value: "chartIcon", sortable: false }
            ],
            headers: [
                {
                    text: "Symbol",
                    value: "symbol",
                    align: "left",
                    width: 120,
                    description: "Product name",
                    showFilter: true
                }, {
                    text: "Long",
                    value: "pb_long",
                    align: "left",
                    width: 100,
                    description: "Total long position",
                    showFilter: true
                }, {
                    text: "Short",
                    value: "pb_short",
                    align: "left",
                    width: 100,
                    description: "Total short position",
                    showFilter: true
                }, {
                    text: "Net",
                    value: "netPosition",
                    align: "left",
                    width: 100,
                    description: "Total net position",
                    showFilter: true
                }, {
                    text: "Daily Net",
                    value: "netPnl",
                    align: "left",
                    width: 120,
                    description: "Total net pnl (float eod + closed)",
                    showFilter: true
                }, {
                    text: "Hedge Net",
                    value: "hg_net",
                    align: "left",
                    width: 80,
                    description: "Total hedge account net position",
                    showFilter: true
                }, {
                    text: "Hedge PnL",
                    value: "hg_float",
                    align: "left",
                    width: 80,
                    description: "Total hedge account floating pnl",
                    showFilter: true
                }, {
                    text: "Unrealised (EOD)",
                    value: "floatingPnl",
                    align: "left",
                    width: 140,
                    description: "Total floating PNL (cal by EOD)",
                    showFilter: true
                }, {
                    text: "Unrealised (MT4)",
                    value: "floatingPnlMt4",
                    align: "left",
                    width: 140,
                    description: "Total floating PNL (raw MT4 pnl)",
                    showFilter: true
                }, {
                    text: "Realised",
                    value: "closedPnl",
                    align: "left",
                    width: 100,
                    description: "Total closed pnl (EOD + Non-EOD)",
                    showFilter: true
                }, {
                    text: "Long VWAP",
                    value: "avgBuyPrice",
                    align: "left",
                    description: "Average long entry price",
                    showFilter: true
                }, {
                    text: "Short VWAP",
                    value: "avgSellPrice",
                    align: "left",
                    description: "Average short entry price",
                    showFilter: true
                }
                // {
                //     text: "EOD Price", value: "eod", align: "left", description: "End of day price",
                //     showFilter: true
                // },
            ],
            loadSubTable: false,
            search: "",
            searchLogin: "",
            itemsPerPage: -1,
            newObject: {},
            hedgeObject: {},
            symbolRules: ["-", "c", "s", "."],
            currentExpanded: "",
            symbolDetailObject: [],
            root: 0,
            brokers: [],
            showPositionChart: 0,
            showNexposureChart: 0,
            socketPk: null,
            socketHedge: null,
            socketConnectionPool: {},
            floatTitle: {},
            brokerSymbolRule: [],
            dialogCloseSummaryVisible: false,
            closeSummaryLoginData: [],
            closeSummaryLoginHeaders: [
                {
                    text: "Login",
                    align: "start",
                    sortable: false,
                    value: "login"
                },
                { text: "Profit", value: "profit", sortable: true }
            ],
            searchCloseSummaryLogin: "",
            closeSummarySymbolData: [],
            closeSummarySymbolHeaders: [
                {
                    text: "Symbol",
                    align: "start",
                    sortable: false,
                    value: "symbol"
                },
                { text: "Profit", value: "profit", sortable: true }
            ],
            searchCloseSummarySymbol: "",
            closeSummaryRawData: [],
            closeSummaryRawHeaders: [
                { text: "Login", align: "start", sortable: false, value: "login" },
                { text: "Type", value: "type", sortable: true },
                { text: "Ticket", value: "ticket" },
                { text: "Profit", value: "profit", sortable: true },
                { text: "Open Time", value: "open_time", sortable: true },
                { text: "Close Time", value: "close_time", sortable: true },
                { text: "Open Price", value: "open_price" },
                { text: "Close Price", value: "close_price" },
                { text: "Comment", value: "comment" },
                { text: "Reason", value: "tradeReason" },
                { text: "Symbol", value: "symbol" },
                { text: "Volume", value: "volume" },
                { text: "Book", value: "book" }
            ],
            searchCloseSummaryRaw: "",
            selectedKey: "login",
            dialogWidth: "500px",
            closeSummarySymbol: "",
            // today: new Date().toISOString().substr(0, 10),
            today: moment().format("YYYY-MM-DD"),
            showClosePnlDialog: 0,
            loginDetailHeight: 0,
            showLoginDetailLinkRiskHub: 0,
            // showLoginChartHistory: 0,//markmark
            showLoginChartHistory: 1,
            LoginHistoryChartTimeDate: [],
            loginValue: "",
            LoginHistoryChartSeriesData: [],
            LoginHistoryChartLegendData: [],
            stockList: ["AMZN", "LULU", "SRCL", "IBKR", "FB", "TSLA", "NVDA", "PYPL", "NFLX", "GL", "CLX", "BABA", "BIDU", "UBER", "AXP", "AMD", "BBY", "COST", "CI", "GM", "CSCO", "ABNB", "ZM", "VZ", "BAC", "PDD", "AFRM", "JPM", "V", "CL", "WMT", "DIS", "CRM", "NKE", "KO", "MRK", "IBM", "BA", "CAT", "MMM", "AMGN", "DOW", "MAR", "PG", "MA", "AAPL", "AEP", "GOOG", "MSFT", "PLTR"]
        };
    },
    computed: {
        ...mapState(["wssUrlMap", "hedgeMap"]),
        /**
         * 表头的filter
         *
         * @return  {[type]}  [return description]
         */
        filteredDataSource () {
            const conditions = [];

            if (this.tableFilter.symbol) conditions.push(this.filterSymbol);
            if (this.tableFilter.long) conditions.push(this.filterLong);
            if (this.tableFilter.net) conditions.push(this.filterNet);
            if (this.tableFilter.short) conditions.push(this.filterShort);
            if (this.tableFilter.netPnl) conditions.push(this.filterNetPnl);
            if (this.tableFilter.netPosition) conditions.push(this.filterNetPosition);

            if (conditions.length > 0) {
                return this.newObject.filter((item) => {
                    return conditions.every((condition) => {
                        console.log(condition);
                        return condition(item);
                    });
                });
            }
            return this.newObject;
        },

        filteredAllBookData () {
            return this.allBooks.data;
        }
    },
    watch: {
        /**
         * Watch on filteredDataSource change to change value in compare
         *
         * @param   {[type]}  nv  [nv description]
         * @param   {[type]}  ov  [ov description]
         *
         * @return  {[type]}      [return description]
         */
        filteredDataSource (nv, ov) {
            const res = {};
            let flag = false;
            if (ov.length === undefined || ov.length === 0 || ov.undefined) {
                flag = true;
            }
            for (let i = 0; i < nv.length; i++) {
                const compares = {};

                let floatingPnl = 0;
                let floatingPnlMt4 = 0;
                let netPnl = 0;

                if (!flag) {
                    floatingPnl = this.compareUpdatedValue(ov[i].floatingPnl, nv[i].floatingPnl);
                    floatingPnlMt4 = this.compareUpdatedValue(ov[i].floatingPnlMt4, nv[i].floatingPnlMt4);
                    netPnl = this.compareUpdatedValue(ov[i].netPnl, nv[i].netPnl);
                }

                floatingPnl = this.getColor(floatingPnl);
                floatingPnlMt4 = this.getColor(floatingPnlMt4);
                netPnl = this.getColor(netPnl);

                compares.floatingPnl = floatingPnl;
                compares.floatingPnlMt4 = floatingPnlMt4;
                compares.netPnl = netPnl;

                res[nv[i].symbol] = compares;
            }
            this.compares = res;
        },
        selectedBrokerId (nv, ov) {
            this.selectedBroker = this.getBrokerNameById(nv);
            this.allBokPevious = 0;
            this.loadExposure = 0;
            this.$nextTick(() => {
                this.loadExposure = 1;
            });
            this.loadPercentControlledWearhouse();
        },
        /**
         * 监控book change
         * @param  {[type]} new value [description]
         * @param  {[type]} old value [description]
         * @return {[type]}    [description]
         */
        selectedBook (nv, ov) {
            console.log("Access SelectedBook");
            const self = this;

            // reset search login term
            this.searchLogin = "";

            // 赋值当前book
            // this.selectedBook = nv;

            // close socket if pk and hedge is not null
            if (this.socketPk != null) this.socketPk.close();
            if (this.socketHedge != null) this.socketHedge.close();

            // set hedge group params
            // let hedgeChannel = this.selectedBroker + "-PkBook:";

            let hedgeGroup = "";

            self.hedgeObject = {};

            // 特定broker对应hedge组的信息
            if (this.hedgeMap.hasOwnProperty(this.selectedBrokerId)) {
                // hedgeChannel += this.hedgeMap[this.selectedBrokerId];
                hedgeGroup = this.hedgeMap[this.selectedBrokerId];
            } else {
                if (this.socketHedge != null) this.socketHedge.close();
            }

            // get ready for socket connection url
            let wssUrlPk = "";
            wssUrlPk = this.wssUrlMap[this.selectedBrokerId.toString()] + this.selectedBroker + "&groupName=" + this.selectedBook;
            this.wssUrlPk = wssUrlPk;
            const wssUrlHedge = this.wssUrlMap[this.selectedBrokerId.toString()] + this.selectedBroker + "&groupName=" + hedgeGroup;
            console.log(wssUrlPk);

            // console.log("Hedge URL: ", wssUrlHedge);

            // 建立当前PK组的连接, 来接收表格实时信息
            // this.socketPk = new WebSocket(wssUrlPk);
            this.socketConnection(wssUrlPk);
            // wssUrlHedge += this.selectedBroker;
            // wssUrlHedge += "&groupName=" + hedgeGroup;

            // 建立当前broker的对冲组的连接
            this.socketHedge = new WebSocket(wssUrlHedge);

            this.newObject = [];
            (this.newSummary = { L: 0, S: 0, N: 0, FPNL: 0, CPNL: 0, NPNL: 0 }),
            (this.expanded = []);

            // // 获取selectedbook后建立socket然后传递当前选择的channel，获取当前连接信息。
            // const selectedChannel = `${this.selectedBroker}-PkBook:${this.selectedBook}`;

            // PK socket建立事件

            this.socketHedge.onopen = function (data) {
                console.log("Connection Open for Hedge");
            };

            // 对冲组逻辑
            this.socketHedge.onmessage = function (message) {
                const data = self.unzipVersion(message.data);
                const result = self.calculate(data, self.selectedBrokerId);
                self.hedgeObject = result.newObject;
                // console.info(result);
            };

            setTimeout(() => {
                // 其它book的socket订阅事件
                this.allWssHandler().establish();
            }, 400);

            if (this.loadExposure === 1) {
                this.loadExposure = 0;
                this.$nextTick(() => {
                    this.loadExposure = 1;
                });
            }

            if (this.wachSocketReciving !== null) {
                clearInterval(this.wachSocketReciving);
            }
            this.perviousNewObject = null;
            this.setWachSocketDATA();
            this.storeUnqiueNewObject = [];
            this.perviousSummary = [];
            this.perviousAllBookData = [];
            this.$store.state.snackbar = false;
            self.$store.state.snacktimeout = 2000;
        },
        /**
         * 监控当前book列表
         * @param  {[type]} nv [description]
         * @param  {[type]} ov [description]
         * @return {[type]}    [description]
         */
        listBook (nv, ov) {
            if (nv.length != 0) {
                this.allBooks.data = [];
                this.floatTitle = {};
                for (let i = 0; i < nv.length; i++) {
                    // console.log(nv[i]);
                    // if (nv[i].book === this.selectedBook) continue;
                    this.floatTitle[nv[i].book] = 0;

                    const temp = { L: 0, S: 0, N: 0, FPNL: 0, CPNL: 0, NPNL: 0, FPNLMT4: 0, book: nv[i].book };
                    this.allBooks.data.push(temp);
                }
                this.allWssHandler().remove(ov);
                this.selectedBook = nv[0].book;
                // this.loadGlobalNotificationBar = 0;
                // this.$nextTick(() => {
                //     this.loadGlobalNotificationBar = 1;
                // });
                this.loadBookVolumeusd();
                this.loadExposureLargestProduct();
                this.loadChartMtdPnl();
                this.loadChartMtdVol();
            }
        },
        // 监听close summary对话框是否打开，关闭时清空图表数据
        dialogCloseSummaryVisible (nv, ov) {
            if (!nv) {
                self = this;
                self.closeSummaryLoginData = [];
                self.closeSummarySymbolData = [];
                self.closeSummaryRawData = [];
                self.searchCloseSummaryLogin = "";
                self.searchCloseSummarySymbol = "";
                self.searchCloseSummaryRaw = "";
            }
        }
    },
    methods: {
        clickDisconnect () {
            this.connectionBroken = !this.connectionBroken;
            console.log(this.connectionBroken);
        },
        changeSort (column) {
            if (this.pagination.sortBy === column) {
                this.pagination.descending = !this.pagination.descending;
            } else {
                this.pagination.sortBy = column;
                this.pagination.descending = false;
            }
        },
        socketConnection (wssUrlPk) {
            // get the MT4 time
            const localtime = new Date();
            const copiedDate = new Date(localtime.getTime());

            const offerset = this.getMT4OfferSet(localtime);
            const mt4day = (new Date(localtime.setHours(copiedDate.getHours() + offerset))).getDay();

            const isweekend = (!!(mt4day === 6 || mt4day === 0));
            const self = this;
            this.socketPk = new WebSocket(wssUrlPk);

            this.socketPk.onopen = function (event) {
                if (!isweekend) {
                    self.lossconnectionCounter = self.lossconnectionTimes;
                    setTimeout(() => {
                        // self.snackBarSuccess("Successfully connected to the stream");
                    }, 500);
                }
                self.connectionBroken = false;
            };

            this.socketPk.onclose = function (event) {
                console.log("socket pk closed");
            };

            this.socketPk.onerror = function (event) {
            };

            self.symbolDetailObject = [];

            // PK socket有消息推送的逻辑
            this.socketPk.onmessage = function (message) {
                // 解压信息, 因为socket传过来的信息都是压缩的
                const data = self.unzipVersion(message.data);

                // 实时计算表格仓位信息
                const result = self.calculate(data, self.selectedBrokerId, false);
                self.newSummary = result.newSummary;
                
                self.newObject = Object.values(result.newObject);
                // console.log(self.newObject);

                self.newObject = self.newObject.filter((item) =>
                    Math.abs(item.netPosition) != 0 ||
                    Math.abs(item.pbLong) != 0 ||
                    Math.abs(item.pbShort) != 0 ||
                    Math.abs(item.floatingPnl) != 0 ||
                    Math.abs(item.floatingPnlMt4) != 0 ||
                    Math.abs(item.closedPnl) != 0
                );
                // 计算当前expand的表格row信息
                self.symbolDetailObject = self.calculateLogin(data, self.currentExpanded, self.selectedBrokerId);
                self.symbolDetailObject = self.symbolDetailObject.filter(item => (item.volume_long != 0 || item.volume_net != 0 || item.volume_short != 0));

                if (self.selectedBrokerId === 10 && self.selectedBook === "S") {
                    const index = self.symbolDetailObject.findIndex(item => item.login === "8802503");
                    self.symbolDetailObject.splice(index, 1);
                    // console.log(self.newSummary);
                }
                if (self.selectedBrokerId === 10 && self.selectedBook === "B") {
                    for (let i = 0; i < self.symbolDetailObject.length; i++) {
                        if (self.symbolDetailObject[i].login === "8802503") {
                            self.symbolDetailObject[i].volume_long += self.symbolDetailObject[i].volume_long;
                            self.symbolDetailObject[i].volume_short += self.symbolDetailObject[i].volume_short;
                            self.symbolDetailObject[i].volume_net += self.symbolDetailObject[i].volume_net;
                            break;
                        }
                    }
                    // const index = self.symbolDetailObject.findIndex(item => item.login === "8802503");
                    // self.symbolDetailObject.splice(index, 1);
                    // console.log(self.symbolDetailObject.filter(item => item.login === "8802503"));
                }
            };
        },
        getColor (comparenum) {
            if (comparenum === -1) {
                return "red";
            } else if (comparenum === 1) {
                return "green";
            } else {
                return "rgba(255, 0, 0, 0)";
            }
        },
        /**
         * modify this.allbooks.data - add new attribute and tuples
         *      if varaiable is number then keep it
         *      if inserted data is empty string change to -> 'None'
         *
         * @param attribute  attribute name - string
         * @param tuples     data inserted in - object
         *
         * @return void
         */

        modifyallBooks (attribute, data) {
            this.allBooks.data = this.allBooks.data.map((ele) => {
                const variableincoming = data[ele.book];
                if (typeof variableincoming === "number") {
                    ele[attribute] = variableincoming;
                } else {
                    ele[attribute] = variableincoming || "None";
                }
                return ele;
            });
        },

        /**
         * Get the largest exposure product in each book
         * @return  {[type]}  [return description]
         */
        loadBookVolumeusd () {
            const self = this;
            const book = self.listBook.map(item => item.book);
            const params = { broker_id: self.selectedBrokerId, book: book };
            self.bookVolumeUsd = {};
            apiLoadBookVolumeusd.create(params).then(res => {
                self.bookVolumeUsd = res.data;
                this.modifyallBooks("vol", res.data);
            });
        },
        /**
         * Get the largest exposure product in each book
         * @return  {[type]}  [return description]
         */
        loadExposureLargestProduct () {
            const self = this;
            const book = self.listBook.map(item => item.book);
            const params = { broker: self.selectedBroker, book: book };
            self.chartExposureLargestProduct = {};
            apiChartExposureLargestProduct.create(params).then(res => {
                self.chartExposureLargestProduct = res.data;
                this.modifyallBooks("lxp1", res.data);
            });
        },
        /**
         * Month to date pnl
         * @return  {[type]}  [return description]
         */
        loadChartMtdPnl () {
            const self = this;
            const book = self.listBook.map(item => item.book);
            const params = { broker_id: self.selectedBrokerId, book: book };
            self.chartMtdPnl = {};
            apiChartMtdPnl.create(params).then(res => {
                self.chartMtdPnl = res.data;
                this.modifyallBooks("mtdPnl", res.data);
            });
        },
        /**
         * Month to date Volume
         * @return  {[type]}  [return description]
         */
        loadChartMtdVol () {
            const self = this;
            const book = self.listBook.map(item => item.book);
            const params = { broker_id: self.selectedBrokerId, book: book };
            self.chartMtdVol = {};
            apiChartMtdVol.create(params).then(res => {
                self.chartMtdVol = res.data;
                this.modifyallBooks("mtdVol", res.data);
            });
        },
        /**
         * Load percent controlled wearhouse
         * @return  {[type]}  [return description]
         */
        loadPercentControlledWearhouse () {
            const self = this;
            const params = {
                broker_id: self.selectedBrokerId,
                broker_name: self.selectedBroker
            };
            apiOzPercentControlled.fetch(params).then(res => {
                self.configPercentContorl = res.data;
            });
        },
        filterSymbol (item) { return item.symbol.toString().toLowerCase().includes(this.tableFilter.symbol.toLowerCase()); },
        filterLong (item) { return parseFloat(this.tableFilter.pb_long) <= Math.abs(item.pbLong); },
        filterShort (item) { return parseFloat(this.tableFilter.pb_short) <= Math.abs(item.pbShort); },
        filterNet (item) { return parseFloat(this.tableFilter.netPosition) <= Math.abs(item.netPosition); },
        filterNetPnl (item) { return parseFloat(this.tableFilter.netPnl) <= Math.abs(item.netPnl); },
        filterNetPosition (item) { return parseFloat(this.tableFilter.netPosition) <= Math.abs(item.netPosition); },
        /**
         * 显示表头的filter
         *
         * @param   {[type]}  headers  [headers description]
         * @return  {[type]}           [return description]
         */
        showHeaderFilter (data) {
            for (let i = 0; i < this.headers.length; i++) {
                if (this.headers[i].value === data.value) {
                    this.headers[i].showFilter = true;
                } else {
                    this.headers[i].showFilter = false;
                }
            }
        },
        /**
         * 打开filter的时候把focus放在搜索框上面
         * @param   {[type]}  item  [item description]
         * @return  {[type]}        [return description]
         */
        setFocus (item) {
            setTimeout(() => {
                this.$refs[`${item}`].focus();
            }, 200);
        },
        /**
         * 检查表格表头显示与否
         * @return  {[type]}  [return description]
         */
        checkHeaderActive (header) {
            const headerData = this.headers.filter(item => item.value === header);
            if (headerData.length === 0) return false;
            return (headerData[0].align === "left");
        },
        /**
         * 显示pk 监控表头的column filter
         * @return  {[type]}  [return description]
         */
        togglePkTableFilter () {
            this.showPkTableFilter = !this.showPkTableFilter;
        },
        /**
         * 去到risk页面的具体账户详情
         * @param  {[type]} login [description]
         * @return {[type]}       [description]
         */
        goToTag (login) {
            if (this.showLoginDetailLinkRiskHub === 1) {
                const end = this.selectedBrokerId.toString() + "-" + login;
                // let remoteUrl = "http://localhost:2228/#/tag/detail/" + end;
                const remoteUrl = "https://risk.sigmatm.com.au/#/tag/detail/" + end;
                // let remoteUrl = "http://localhost:2228/#/tag/detail/" + end;
                window.open(remoteUrl, "_blank");
            }
        },
        /**
         * 获取eod价格
         * @param  {[type]} data [description]
         * @return {[type]}      [description]
         */
        extractEodPrice (data, hasPercentage) {
            let result = "";
            // let result = "<p class='mb-2'>Percentage Controlled Warehousing: ";
            // if (hasPercentage) result += "Yes";
            // if (!hasPercentage) result += "No";
            // result += "</p>";
            result += "<p class='mb-2'>Eod Price</p>";
            for (const item in data) {
                result += "<p class='mb-0'>" + item + "</p>";
                result += "<p class='mb-0'>Ask: " + data[item].eodAsk.toString() + " Bid: " + data[item].eodBid.toString() + "</p>";
            }
            return result;
        },
        /**
         * Websocket连接处理
         * @return {[type]} [description]
         */
        allWssHandler () {
            const self = this;
            return {
                /**
                 * 建立连接
                 * @return {[type]} [description]
                 */
                establish () {
                    // 初始化两个node的全局socket连接
                    // let node2 = [1, 4, 8];
                    // let node1 = [3, 2];
                    let multipleWssUrlRoot = self.$store.state.wssUrlMap[self.selectedBrokerId];

                    // if (node2.includes(self.selectedBrokerId)) {
                    //     multipleWssUrlRoot = "wss://wss-node2.sigmatm.com.au:9090/positionsByGroup?platform=";
                    // } else {
                    //     multipleWssUrlRoot = "wss://wss.sigmatm.com.au:9090/positionsByGroup?platform=";
                    // }

                    multipleWssUrlRoot += self.selectedBroker;
                    multipleWssUrlRoot += "&groupName=";

                    for (let i = 0; i < self.listBook.length; i++) {
                        const currentBook = self.listBook[i].book;
                        // if (currentBook === self.selectedBook) continue;

                        const wssUrlMultiple = multipleWssUrlRoot + currentBook;

                        // console.log(wssUrlMultiple);

                        // 建立socket连接池
                        if (self.socketConnectionPool[currentBook] === null || self.socketConnectionPool[currentBook] === undefined) {
                            // console.log(wssUrlMultiple);
                            // console.log("Try to establish connection for ", currentBook);
                            self.socketConnectionPool[currentBook] = new WebSocket(wssUrlMultiple);
                            self.socketConnectionPool[currentBook].onopen = function (data) {
                                // console.log("Connected: ", data);
                            };

                            self.socketConnectionPool[currentBook].onmessage = function (message) {
                                // console.log("Current Book ", message);
                                const data = self.unzipVersion(message.data);
                                const result = self.calculate(data, self.selectedBrokerId, false, currentBook);
                                // self.floatTitle[currentBook] = result.newSummary.NPNL;

                                for (let a = 0; a < self.allBooks.data.length; a++) {
                                    if (self.allBooks.data[a].book === currentBook) {
                                        self.allBooks.data[a].L = result.newSummary.L;
                                        self.allBooks.data[a].S = result.newSummary.S;
                                        self.allBooks.data[a].N = result.newSummary.N;
                                        self.allBooks.data[a].FPNL = result.newSummary.FPNL;
                                        self.allBooks.data[a].CPNL = result.newSummary.CPNL;
                                        self.allBooks.data[a].NPNL = result.newSummary.NPNL;
                                        self.allBooks.data[a].FPNLMT4 = result.newSummary.FPNLMT4;
                                        break;
                                    }
                                }
                                // let index = self.allBooks.data.findIndex(item => item.book = currentBook);

                                // self.allBooks.data[index] = result.newSummary;

                                // if (index === -1) {
                                // self.allBooks.data.push(result.newSummary);
                                // } else {
                                // }
                                // console.log(result);
                            };
                        } else {
                            // console.log("Connection already exist ", currentBook);
                        }
                    }
                },
                /**
                 * 关闭旧book的socket
                 * @param  {[type]} oldBookList [description]
                 * @return {[type]}             [description]
                 */
                remove (oldBookList) {
                    // for (let i = 0; i < self.socketConnectionPool.length; i++) {
                    // 	self.socketConnectionPool[i].close();
                    // 	delete self.socketConnectionPool[i];
                    // }
                    // console.log("Old ", oldBookList);
                    for (let i = 0; i < oldBookList.length; i++) {
                        const currentBook = oldBookList[i].book;
                        if (self.socketConnectionPool[currentBook] != null) {
                            // console.log("Remove socket ", currentBook);
                            self.socketConnectionPool[currentBook].close();
                            delete self.socketConnectionPool[currentBook];
                        }
                    }
                }
            };
        },
        /**
         * 获取当前选中产品在当前组别中所有login的open trades总结
         * @return {[type]} [description]
         */
        openLoginSummary () {
            const self = this;
            const params = {
                broker_id: self.selectedBrokerId,
                book: self.selectedBook,
                symbol: self.openSymbol,
                broker_name: self.selectedBroker
            };
            apiTradesLoginSummary.fetch(params).then((res) => {
                self.openTradesSummary = res.data;
                const height = Object.keys(res.data).length * 50;
                if (height === 0) {
                    self.loginDetailHeight = 100;
                    return;
                }
                self.loginDetailHeight = height >= 300 ? 300 : 160;
            });
        },
        /**
         * 格式化tooltip
         * @param  {[type]} data [description]
         * @return {[type]}      [description]
         */
        formatToolTip (data) {
            if (JSON.stringify(data) === "{}") return;
            if (data.length === 0) {
                return "";
            }
            let result = "<p class=\"mb-1\">Login Info</p>\n";
            result += "<p class=\"mb-1\">Book: " + data[0].book.toString() + "</p>\n";
            result += "<p class=\"mb-1\">Balance: " + data[0].balance.toString() + "</p>\n";
            result += "<p class=\"mb-1\">DPM: " + data[0].dpm.toString() + "</p>\n";
            result += "<p class=\"mb-1\">Equity: " + data[0].equity.toString() + "</p>\n";
            result += "<p class=\"mb-1\">Prev Profit: " + data[0].previous_profit.toString() + "</p>\n";
            result += "<p class=\"mb-1\">Profit: " + data[0].profit.toString() + "</p>\n";
            result += "<p class=\"mb-1\">Volume USD: " + data[0].volume_usd.toString() + "</p>\n";
            result += "<p class=\"mb-1\">Net Deposit: " + data[0].net_deposit.toString() + "</p>";
            result += "<p class=\"mb-1\">Deposit: " + data[0].deposit.toString() + "</p>";
            result +=
                "<p class=\"mb-1\">Withdraw: " +
                data[0].withdraw.toString() +
                "</p>";
            result +=
                "<p class=\"mb-1\">Swaps: " + data[0].swaps.toString() + "</p>";

            return result;
        },
        /**
         * 获取选中账号的apr数据
         * @return {[type]} [description]
         */
        loginDetailHandler () {
            const self = this;

            return {
                // 调用特定账号的summary信息
                load (login) {
                    const params = { login: login };
                    self.showLoginDetail = false;
                    apiLoginDetail.fetch(params).then((res) => {
                        self.loginDetail = res.data;
                        self.showLoginDetail = true;
                    });
                },
                remove () {
                    self.showLoginDetail = false;
                }
            };
        },
        /**
         * 输出hedge组的信息
         * @param  {[type]} data  [description]
         * @param  {[type]} hedge [description]
         * @return {[type]}       [description]
         */
        outputHedge (data, hedge) {
            if (hedge.hasOwnProperty(data.item.symbol)) {
                return {
                    net: hedge[data.item.symbol].netPosition,
                    netPnl: hedge[data.item.symbol].netPnl
                };
            }

            return { net: 0, netPnl: 0 };
        },
        /**
         * 自定义表header的检查
         * @param  {[type]} data [description]
         * @return {[type]}      [description]
         */
        checkHeader (data) {
            for (let i = 0; i < this.headers.length; i++) {
                if (this.headers[i].value === data.value) {
                    if (this.headers[i].align === "left") {
                        this.headers[i].align = " d-none";
                    } else {
                        this.headers[i].align = "left";
                    }
                    break;
                }
            }
        },
        /**
         * 表格row点击事件处理
         * @param  {[type]} data [description]
         * @return {[type]}      [description]
         */
        rowClicked (data) {
            this.expanded = [];
            this.symbolDetailObject = [];
            this.loadSubTable = false;
            this.openSymbol = data.symbol;

            // 获取当前login的summary信息
            this.openLoginSummary();

            if (this.currentExpanded === "" || this.currentExpanded === undefined) {
                this.currentExpanded = data.symbol;
                this.expanded = [data];
                this.$nextTick(() => {
                    this.loadSubTable = true;
                });
            } else if (this.currentExpanded != data.symbol) {
                this.currentExpanded = data.symbol;
                this.expanded = [data];

                this.$nextTick(() => {
                    this.loadSubTable = true;
                });
            } else {
                this.currentExpanded = "";
            }
        },
        /**
         * On view change
         * @param  {[type]} key [description]
         * @return {[type]}     [description]
         */
        changeView (key) {
            // load pk
            if (key === 1) {
                this.loadPk = 1;
                this.loadChart = 0;
                this.loadExposure = 0;
            }

            // load chart
            if (key === 2) {
                this.loadPk = 0;
                this.loadChart = 1;
                this.loadExposure = 0;
            }

            // load exposure
            if (key === 3) {
                this.loadPk = 0;
                this.loadChart = 0;
                this.loadExposure = 1;
            }
        },
        // /**
        //  * Load book group
        //  * @param {*}
        //  */
        // loadBookGroup() {
        // 	let self = this;
        // 	let params = { broker_id: this.selectedBrokerId, ignore_hidebook: 1 };
        // 	apiBook.fetch(params).then((res) => {
        // 		self.listBook = res.data;
        // 		// console.log("🚀 ~ file: Home.vue ~ line 434 ~ apiBook.fetch ~ self.listBook", self.listBook);
        // 	});
        // },
        /**
         * Event listener on broker id change
         * @param  {[type]} broker    [description]
         * @param  {[type]} broker_id [description]
         * @return {[type]}           [description]
         */
        changeBroker (broker, brokerId) {
            this.selectedBrokerId = brokerId;
            this.selectedBroker = broker;
            this.selectedBook = "";
            this.listBook = this.getBookListByBrokerId(brokerId);
            // this.loadBookGroup();
        },
        changeBook (book) {
            this.selectedBook = book;
        },
        filterTable (value, search, item) {
            return (
                value != null &&
                search != null &&
                typeof value === "string" &&
                value.toString().indexOf(search) !== -1
            );
        },
        customSort (items, sortBy, sortDesc) {
            items.sort((a, b) => {
                if (sortBy[0] === "netPosition") {
                    if (!sortDesc[0]) {
                        return (
                            Math.abs(a.netPosition) - Math.abs(b.netPosition)
                        );
                    } else {
                        return (
                            Math.abs(b.netPosition) - Math.abs(a.netPosition)
                        );
                    }
                } else if (sortBy[0] === "closedPnl") {
                    if (!sortDesc[0]) {
                        return a.closedPnl - b.closedPnl;
                    } else {
                        return b.closedPnl - a.closedPnl;
                    }
                } else if (sortBy[0] === "floatingPnl") {
                    if (!sortDesc[0]) {
                        return a.floatingPnl - b.floatingPnl;
                    } else {
                        return b.floatingPnl - a.floatingPnl;
                    }
                } else if (sortBy[0] === "floatingPnlMt4") {
                    if (!sortDesc[0]) {
                        return a.floatingPnlMt4 - b.floatingPnlMt4;
                    } else {
                        return b.floatingPnlMt4 - a.floatingPnlMt4;
                    }
                } else if (sortBy[0] === "pb_long") {
                    if (!sortDesc[0]) {
                        return a.pbLong - b.pbLong;
                    } else {
                        return b.pbLong - a.pbLong;
                    }
                } else if (sortBy[0] === "pb_short") {
                    if (!sortDesc[0]) {
                        return a.pbShort - b.pbShort;
                    } else {
                        return b.pbShort - a.pbShort;
                    }
                } else if (sortBy[0] === "netPnl") {
                    if (!sortDesc[0]) {
                        return a.netPnl - b.netPnl;
                    } else {
                        return b.netPnl - a.netPnl;
                    }
                } else if (sortBy[0] === "symbol") {
                    if (!sortDesc[0]) {
                        if (a[sortBy[0]] > b[sortBy[0]]) {
                            return 1;
                        } else {
                            return -1;
                        }
                        return 0;
                    } else {
                        if (a[sortBy[0]] > b[sortBy[0]]) {
                            return -1;
                        } else {
                            return 1;
                        }
                        return 0;
                    }
                } else {
                    if (!sortDesc[0]) {
                        return a[sortBy[0]] - b[sortBy[0]];
                    } else {
                        return b[sortBy[0]] - a[sortBy[0]];
                    }
                }
            });
            return items;
        },
        roundDecimal (data, decimal) {
            if (data === undefined) {
                return 0;
            }

            return data.toFixed(decimal);
        },
        getAveragePrice (total, vol, digits) {
            if (total === 0 || total === undefined || total === null) {
                return 0;
            }
            if (vol === 0 || vol === undefined || vol === null) {
                return 0;
            }
            return (total / vol).toFixed(digits);
        },
        loadAlertChannel () {
            const self = this;
            return apiAlertChannel.fetch().then((res) => {
                self.alertChannel = res.data.channel;
                localStorage.setItem(
                    "alertHide",
                    JSON.stringify(res.data.alert_hide)
                );
                localStorage.setItem(
                    "alertChannel",
                    JSON.stringify(self.alertChannel)
                );
            });
        },
        /**
         * alert notification规则的更新及读取
         * @return {[type]} [description]
         */
        alertNotificationRuleHandler () {
            const self = this;
            const params = { broker_id: self.selectedBrokerId };
            return apiSettingsUserAlertRule.fetch(params).then((res) => {
                localStorage.setItem(
                    "alertNotificationSettings",
                    JSON.stringify(res.data)
                );
            });
        },
        /**
         * 获取当前close summary的symbol
         * @param  {[type]} item [description]
         * @return {[type]}     [description]
         */
        showCloseSummaryDialog (item) {
            const self = this;
            const symbol = item.symbol;
            self.loadLoginTable(symbol);
            self.loadSymbolTable(symbol);
            self.loadRawTable(symbol);
            self.closeSummarySymbol = symbol;
            this.$nextTick(() => {
                self.dialogCloseSummaryVisible = true;
            });
        },
        /**
         * 在对话框加载close summary login表
         * @param  {[type]} symbol [description]
         * @return {[type]}     [description]
         */
        loadLoginTable (symbol) {
            const self = this;
            // let yesterday = this.getYesterday().format("YYYY-MM-DD");
            const params = {
                broker: self.selectedBroker,
                broker_id: self.selectedBrokerId,
                // target_date: yesterday,
                target_date: self.today,
                symbol: symbol,
                book: self.selectedBook,
                mode: "login"
            };

            apiTradesCloseSummary.fetch(params).then((res) => {
                self.closeSummaryLoginData = res.data;
            });
        },
        /**
         * 在对话框加载close summary symbol表
         * @param  {[type]} symbol [description]
         * @return {[type]}     [description]
         */
        loadSymbolTable (symbol) {
            const self = this;
            // let yesterday = this.getYesterday().format("YYYY-MM-DD");
            const params = {
                broker: self.selectedBroker,
                broker_id: self.selectedBrokerId,
                // target_date: yesterday,
                target_date: self.today,
                book: self.selectedBook,
                symbol: symbol,
                mode: "symbol"
            };
            apiTradesCloseSummary.fetch(params).then((res) => {
                self.closeSummarySymbolData = res.data;
            });
        },
        /**
         * 在对话框加载close summary raw表
         * @param  {[type]} symbol [description]
         * @return {[type]}     [description]
         */
        loadRawTable (symbol) {
            const self = this;
            // let yesterday = this.getYesterday().format("YYYY-MM-DD");
            const params = {
                broker: self.selectedBroker,
                broker_id: self.selectedBrokerId,
                // target_date: yesterday,
                target_date: self.today,
                symbol: symbol,
                book: self.selectedBook,
                mode: "raw"
            };
            apiTradesCloseSummary.fetch(params).then((res) => {
                self.closeSummaryRawData = res.data;
                for (const i in self.closeSummaryRawData) {
                    self.closeSummaryRawData[i].profit =
                        self.closeSummaryRawData[i].profit.toFixed(2);
                    if (self.closeSummaryRawData[i].profit === "-0.00") {
                        self.closeSummaryRawData[i].profit = "0.00";
                    }
                    self.closeSummaryRawData[i].cmd
                        ? (self.closeSummaryRawData[i].type = "Sell")
                        : (self.closeSummaryRawData[i].type = "Buy");
                    // 进行reason切换
                    switch (self.closeSummaryRawData[i].reason) {
                    case 0:
                        self.closeSummaryRawData[i].tradeReason = "Client";
                        break;
                    case 1:
                        self.closeSummaryRawData[i].tradeReason = "Expert";
                        break;
                    case 2:
                        self.closeSummaryRawData[i].tradeReason = "Dealer";
                        break;
                    case 3:
                        self.closeSummaryRawData[i].tradeReason = "Signal";
                        break;
                    case 4:
                        self.closeSummaryRawData[i].tradeReason = "Gateway";
                        break;
                    case 5:
                        self.closeSummaryRawData[i].tradeReason = "Mobile";
                        break;
                    case 6:
                        self.closeSummaryRawData[i].tradeReason = "Web";
                        break;
                    case 7:
                        self.closeSummaryRawData[i].tradeReason = "API";
                    }
                }
            });
        },
        changeTab (key) {
            this.selectedKey = key;
            if (key != "raw") {
                this.dialogWidth = "500px";
            } else {
                this.dialogWidth = "";
            }
        },
        loadLoginHistoryChart (item) {
            this.chartDialog = true;
            const self = this;
            const brokerId = self.selectedBrokerId;
            const params = {
                login: item,
                broker_id: brokerId
            };
            self.chartDialogTitle = "Snapshot - " + item;
            self.loginValue = item;
            apiLoginHistory
                .fetch(params)
                .then((res) => {
                    // console.log(res.data)
                    self.LoginHistoryChartSeriesData = [];
                    self.LoginHistoryChartLegendData = [];
                    self.LoginHistoryChartTimeDate = res.data.xdata;
                    self.LoginHistoryChartTimeDate =
                        self.LoginHistoryChartTimeDate.map(function (str) {
                            return str.substring(2, str.length - 9);
                        });

                    const daily_pnl = {
                        name: "Daily PNL",
                        type: "line",
                        symbolSize: 8,
                        // hoverAnimation: false,
                        emphasis: { scale: false },
                        symbol: "circle",
                        yAxisIndex: 2,
                        data: res.data.ydata.daily_pnl
                    };
                    const balance = {
                        name: "Balance",
                        type: "line",
                        emphasis: { scale: false },
                        symbol: "circle",
                        data: res.data.ydata.balance
                    };
                    const equity = {
                        name: "Equity",
                        type: "line",
                        emphasis: { scale: false },
                        symbol: "circle",
                        data: res.data.ydata.equity
                    };
                    const dpm = {
                        name: "DPM",
                        type: "bar",
                        emphasis: { scale: false },
                        symbol: "circle",
                        yAxisIndex: 1,
                        data: res.data.ydata.dpm
                    };
                    self.LoginHistoryChartLegendData.push(daily_pnl.name);
                    self.LoginHistoryChartSeriesData.push(daily_pnl);
                    self.LoginHistoryChartLegendData.push(balance.name);
                    self.LoginHistoryChartSeriesData.push(balance);
                    self.LoginHistoryChartLegendData.push(equity.name);
                    self.LoginHistoryChartSeriesData.push(equity);
                    self.LoginHistoryChartLegendData.push(dpm.name);
                    self.LoginHistoryChartSeriesData.push(dpm);
                    // console.log(self.LoginHistoryChartTimeDate)
                });
        },
        resetTableBookSelected () {
            this.allBookfilters.book = []
        },
        setWachSocketDATA () {

            this.wachSocketReciving = setInterval(() => {
                // console.log(this.newObject, 1);
                const localtime = new Date();
                const copiedDate = new Date(localtime.getTime());
                
                const offerset = this.getMT4OfferSet(localtime);
                const mt4day = (new Date(localtime.setHours(copiedDate.getHours() + offerset))).getDay();

                const isweekend = (!!(mt4day === 6 || mt4day === 0));

                if (!isweekend) {
                    const floatingPnlValues = this.newObject.map(obj => { return obj.floatingPnl; });

                    // inital pervious data is defined as null and it is not compareable -> skip this checking
                    if (this.perviousNewObject !== null && !this.connectionBroken) {

                        // compare the pervious data with current data 
                        // if matched -> means connection is broken
                        if (this.compareArray(floatingPnlValues, this.perviousNewObject)) { 
                            this.lossconnectionCounter -= 1;

                            // continuous broke for "lossconnectionTimes", send reconnection
                            if (this.lossconnectionCounter  === 0) {
                                this.connectionBroken = true;
                            }
                        } else {
                            // if doest not match reset the counter
                            this.lossconnectionCounter = this.lossconnectionTimes;

                            // the unqiue object append to array
                            if (this.storeUnqiueNewObject.length !== this.snapShotLength) {
                                this.storeUnqiueNewObject.push(this.newObject);
                            }
                            // store new summary
                            if (this.perviousSummary.length !== this.snapShotLength) {
                                this.perviousSummary.push(this.newSummary);
                            }

                            // store all book data
                            if (this.perviousAllBookData.length !== this.snapShotLength) {
                                this.perviousAllBookData.push(this.allBooks.data);
                            }


                            // this.connectionBroken = false;
                        }
                    }
                    if (this.connectionBroken) {
                        // this.snackBarDanger("Reconnecting ...", undefined, 0);
                        console.log('loop starts ->', this.storeUnqiueNewObject, this.loopIndex);
                        console.log('            ->', this.perviousSummary, this.loopIndex);
                        console.log('            ->', this.perviousAllBookData, this.loopIndex);
                        console.log(this.socketPk.readyState)
                        this.socketPk.close();
                        this.allWssHandler().remove(this.listBook);


                        // send reconnection request
                        console.log('sent reconnection request  ', this.socketPk);
                        this.socketConnection(this.wssUrlPk);
                        this.allWssHandler().establish();

                        // if empty data
                        if (this.storeUnqiueNewObject.length !== 0) {

                            // load the stored data into front end 
                            // if each the end then back to inital position
                            this.newObject = this.storeUnqiueNewObject[this.loopIndex];
                            this.newSummary = this.perviousSummary[this.loopIndex];
                            this.allBooks.data = [...this.perviousAllBookData[this.loopIndex]];


                            if (this.loopIndex == this.storeUnqiueNewObject.length - 1) {
                                this.loopIndex = 0;
                            } else {
                                this.loopIndex+=1;
                            }
                        } else {
                            this.newObject = [];
                        }
                    }
                    this.perviousNewObject = [...floatingPnlValues];
                }
            }, this.intervalTimeForCheking);
        }, 
        userFocus () {
            if (document.visibilityState === "visible") {
                console.log("report that user is in focus");
            } else {
                console.log("report that user is out of focus");
                this.socketPk.close();
                this.socketConnection(this.wssUrlPk);
                
                this.allWssHandler().remove(this.listBook);

                setTimeout(() => {
                    // 其它book的socket订阅事件
                    this.allWssHandler().establish();
                }, 400);
            };
        }
    },
    created () {
        // this.allBooks.header.sort((a,b) => {
        //     return a.text.localeCompare(b.text)
        // })
        this.$store.commit("alertSubscribe");

        const data = JSON.parse(localStorage.getItem("bp"));

        const brokerArr = data.broker;
        if (brokerArr.length > 1) {
            this.brokers = brokerArr;
            this.selectedBrokerId = 1;
            this.selectedBroker = this.brokers[0].broker;
            this.root = 1;
        } else {
            this.brokers = brokerArr;
        }
        const brokerId = data.broker[0].broker_id;
        const broker = data.broker[0].broker;
        this.selectedBrokerId = brokerId;
        this.selectedBroker = broker;

        const userFunction = this.getFunction("book");

        if (userFunction.includes("nexposureChart")) {
            this.showNexposureChart = 1;
        }
        if (userFunction.includes("positionChart")) {
            this.showPositionChart = 1;
        }
        if (userFunction.includes("closePnlDialog")) {
            this.showClosePnlDialog = 1;
        }
        if (userFunction.includes("loginDetailLinkRiskHub")) {
            this.showLoginDetailLinkRiskHub = 1;
        }
        if (userFunction.includes("loginChartHistory")) {
            this.showLoginChartHistory = 1;
        }

        document.addEventListener("visibilitychange", this.userFocus);
    },
    async mounted () {
        // this.loadBookGroup();
        this.listBook = this.getBookListByBrokerId(this.selectedBrokerId);

        this.loadPk = 1;
        await this.loadAlertChannel();

        this.loadPercentControlledWearhouse();

        // await this.alertNotificationRuleHandler();
        const socket = this.$store.state.socket;
        const self = this;

        this.$nextTick(() => {});
        setTimeout(() => {
            this.initialChart = 1;
        }, 300);

        // this.loadGlobalNotificationBar = 1;

        this.wachAllBookFPNL = setInterval(() => {
            const res = {};
            let flag = false;
            if (this.allBokPevious === 0) {
                flag = true;
            }
            for (let i = 0; i < this.allBooks.data.length; i++) {
                const compares = {};
                let FPNLcompare = 0;
                if (!flag) {
                    FPNLcompare = this.compareUpdatedValue(
                        this.allBokPevious[i].FPNL, this.allBooks.data[i].FPNL
                    );
                }
                compares.FPNL = this.getColor(FPNLcompare);
                res[this.allBooks.data[i].book] = compares;
            }
            this.allBookFPNLCompares = res;
            const copy = [];
            this.allBooks.data.forEach(ele => {
                copy.push({ FPNL: ele.FPNL });
            });
            this.allBokPevious = copy;
        }, 900);
        this.allBookfilters.book.value = this.allBooks.data.map((el) => {
            return el.book;
        });
        this.allBookfilters.lxp1.value = [...new Set(this.allBooks.data.map((el) => {
            return el.lxp1;
        }))];
    },
    destroyed () {
        console.log("Page Destory");
        if (this.socketPk !== null) this.socketPk.close();
        console.log(this.socketPk.readyState, WebSocket.CLOSED);

        if (this.socketHedge !== null) this.socketHedge.close();
        this.allWssHandler().remove(this.listBook);
        clearInterval(this.wachAllBookFPNL);
        this.$store.state.snackbar = false;
        document.getElementById(this.$store.state.snackbarbtnid).onclick = () => {
            console.log("clicked dismiss");
            this.$store.commit("dismissSnackbar");
        };
        clearInterval(this.wachSocketReciving);
        document.removeEventListener("visibilitychange", this.userFocus);
    }
};
</script>
<style>
.td-up {
    background-color: #27ae60;
}

.td-down {
    background-color: #c0392b;
}

.td-alert {
    background-color: #c0392b;
}
.hover-login-tooltip:hover {
    cursor: pointer;
}
.symbol-detail:hover {
    cursor: pointer;
}

#home-table table > tbody {
    /* height: 600px; */
    overflow-y: scroll;
}
#home-table
    > div.v-data-table__wrapper
    > table
    > tbody
    > tr.v-data-table__expanded.v-data-table__expanded__content
    > td
    > div.v-data-table.v-data-table--dense.v-data-table--fixed-header.theme--dark
    > div
    > table
    > tbody
    > tr:nth-child(n) {
    height: 32px;
}
#home-table
    > div.v-data-table__wrapper
    > table
    > tbody
    > tr.v-data-table__expanded.v-data-table__expanded__content
    > td
    > div.v-data-table.v-data-table--dense.v-data-table--fixed-header.theme--dark
    > div
    > table
    > tbody {
    height: 32px;
}
.home-checkbox .v-label {
    font-size: 12px;
}
.home-checkbox .v-messages {
    display: none;
}
#global-container
    > div.home
    > div
    > div
    > div:nth-child(5)
    > div:nth-child(2)
    > div.col-md-5.col-lg-5.col-12
    > div:nth-child(2)
    > div:nth-child(n)
    > button:hover {
    background-color: #2196f3;
}
#home-table > div.v-data-table__wrapper > table > tbody > tr:nth-child(n) {
    height: 32px;
}
#home-table
    > div.v-data-table__wrapper
    > table
    > tbody
    > tr.v-data-table__expanded.v-data-table__expanded__row:nth-child(n) {
    height: 32px;
}

#home-table table > tbody::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
}

#home-table table > tbody::-webkit-scrollbar
{
    width: 12px;
    background-color: #F5F5F5;
}

#home-table table > tbody::-webkit-scrollbar-thumb
{
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);

    background-color: #555;
}
</style>
